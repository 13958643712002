<template>
	<div id="app" v-title data-title="阳光招采电子招标投标交易平台（汇聚版）">
		<router-view v-if="isRouterAlive" />
	</div>
</template>

<script>
export default {
	provide() {
		// 父组件中通过provide来提供变量，在子组件中通过inject来注入变量。
		return {
			reload: this.reload
		}
	},
	components: {},
	data() {
		return {
			isRouterAlive: true // 控制视图是否显示的变量
		}
	},
	computed: {},
	watch: {},
	methods: {
		reload() {
			this.isRouterAlive = false // 先关闭
			this.$nextTick(() => {
				this.isRouterAlive = true // 再打开
			})
		}
	},
	mounted() {}
}
</script>
<style>
.el-scrollbar .el-scrollbar__wrap {
	overflow-x: hidden;
	padding-bottom: 17px;
	height: 100% !important;
}
.el-select-dropdown .el-scrollbar {
}
.loadingclass {
	z-index: 8888 !important;
}
.el-message {
	z-index: 9999 !important;
}

.user .el-button--small {
	padding-top: 5px !important;
	padding-bottom: 5px !important;
}
tbody .el-button--small {
	padding-top: 4px !important;
	padding-bottom: 4px !important;
}
.el-form--inline {
	display: flex;
}
.el-form--inline .el-form-item__content {
	height: 32px;
}
.el-form--inline .el-form-item__content .el-button--small {
	height: 32px;
	padding-top: 0px;
	padding-bottom: 0px;
}
.fix-bottom-btn .el-button--small {
	height: 32px;
	padding-top: 0px;
	padding-bottom: 0px;
}
.el-dialog__footer .el-button--small {
	height: 32px;
	padding-top: 0px;
	padding-bottom: 0px;
}
.mt25 .el-button--small {
	height: 32px;
	padding-top: 0px;
	padding-bottom: 0px;
}
.supplier-info .el-button--small {
	height: 32px;
	padding-top: 0px;
	padding-bottom: 0px;
}
.el-dialog__body .el-button--small {
	height: 32px;
	padding-top: 0px;
	padding-bottom: 0px;
}
.el-button--small {
	height: 32px;
	padding-top: 0px;
	padding-bottom: 0px;
}
/* .pd20 .el-button--small {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
} */

/* 对于360安全浏览器 */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
	.el-button--small {
		padding-top: 5px !important;
		padding-bottom: 5px !important;
	}
}

/* 修改360极速需求委托样式 */
.el-form-item__content .el-input__prefix {
	left: 0px;
}
.demand-entrust .el-form-item .el-form-item__error {
	word-break: break-word;
	position: relative;
	top: 2px;
}
</style>
