import Vue from 'vue';

Vue.directive('new_emoji', {// 禁止输入特殊字符
    bind: function (el, binding, vnode) {
        // 正则规则可根据需求自定义
        const regRule = /[\/:*?"<>|]/im;
        let $inp = findEle(el, 'input') || findEle(el, 'textarea');
        el.$inp = $inp;
        $inp.handle = function (event) {
            let val = $inp.value;
            $inp.value = val.replace(regRule, '');
            trigger($inp, 'input');
        }
        $inp.addEventListener('keyup', $inp.handle);
    },
    unbind: function (el) {
        el.$inp.removeEventListener('keyup', el.$inp.handle);
    }
});

Vue.directive('emoji', {

});

const findEle = (parent, type) => {
    return parent.tagName.toLowerCase() === type ? parent : parent.querySelector(type)
};

const trigger = (el, type) => {
    const e = document.createEvent('HTMLEvents');
    e.initEvent(type, true, true);
    el.dispatchEvent(e);
};
