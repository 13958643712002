/*
 * aioxs 封接口地址
 * rest(表现层状态转化)url 面向资源
 */
export default {
	// 接口代理配置 见vue.config.js
    // 配置模块顺序为方便查看 尽可能对应views目录顺序
	
	/* 公共 模块 /api/base.js */
	authMenus: '/czy-admin/auth/menus', // 获取当前用户的菜单
	resourceList: '/czy-uaa/resource/list', // 获取资源信息（所有、当前用户）
	accountCheckUniqueAccount: '/czy-uaa/account/checkUniqueAccount', // 校验人员账户名称 是否已存在
	systemBaseCode: '/czy-system/system/baseCode', // 获取基础信息（系统类型、主体类型）
	listTenantEntityTypeByTenantCode: '/czy-uaa/tenantEntity/listTenantEntityTypeByTenantCode', // 区划对应的 系统/主体map
	checkNameOrCreditCodeExist: 'czy-uaa/entity/checkNameOrCreditCodeExist', // 校验用户名/统一社会信用代码是否注册
	systemTree: '/czy-system/system/tree', // 获取系统（行业分类/）树
	
	/* 顶部公共 模块 /api/topHeader.js */
	removeToken: '/czy-uaa/oauth/remove/token', // 退出登录


	/* 登录模块 /api/main.js */
	getPublicKey: '/czy-admin/auth/mock/getPublicKey', // 获取登录密码加密的密钥
	oauthToken: '/czy-admin/auth/mock/login', // TODO 登录
	loginSmsVerificationCode: '/czy-admin/auth/mock/loginSmsVerificationCode',//获取忘记密码的验证码
	loginContrastSmsVerificationCode: '/czy-admin/auth/mock/loginContrastSmsVerificationCode', //重置密码
	resetPwd: '/czy-admin/auth/mock/resetPwd', //修改密码
	

	verifySignedData: '/czy-admin/auth/mock/verifySignedData', // CA登录验签
	calogin: '/czy-admin/auth/mock/calogin', // CA登录
	
	applyQrCode: '/czy-admin/auth/mock/applyQrCode', // 标信通登录方式获取对应的二维码
	qRLogin: '/czy-admin/auth/mock/qRLogin', // 循环获取二维码的扫码状态
	smLoginValidation: '/czy-admin/auth/mock/smLoginValidation', // 扫码授权后验证


	/* 注册模块 /api/main.js */
	getBusinessPlatforms: '/czy-system/platform/getBusinessPlatforms', // 获取主体类型Map-所有
	addEntity: '/czy-uaa/entity/addEntity', // 注册主体（账户）
	getTenantList: '/czy-uaa/tenant/getTenantList', // 获取所有区划

	
	getCode: '/czy-admin/registe/getCode', //供应商-获取验证码
	getImageFromEhb: '/czy-admin/registe/getImageFromEhb', //供应商-获取电子营业执照
	countByName: '/czy-admin/registe/countByName', //供应商-校验企业名称是否重复
	providerRegSubmit: '/czy-admin/registe/providerRegSubmit', //供应商-注册提交


	
	
	/* 系统管理 模块 /api/system.js */
	accountList: '/czy-uaa/account/list', // 账户列表
	accountRoles: '/czy-uaa/account/roles', // 账户角色
	accountRelatedRoles: '/czy-uaa/account/relatedRoles', // 确认分配角色
	accountDel: '/czy-uaa/account/del', // 账户删除
	accountReset: '/czy-uaa/account/reset', // 账户重设密码
	accountEnable: '/czy-uaa/account/enable', // 账户启用/禁用
	accountAdd: '/czy-uaa/account/add', // 账户新增
	accountUpdate: '/czy-uaa/account/update', // 账户修改
	
	roleList: '/czy-uaa/role/list', // 获取所有角色列表
	findResourceByRoleCode: '/czy-uaa/resource/findResourceByRoleCode', // 获取角色资源（权限）
	roleRelatedResource: '/czy-uaa/role/relatedResource', // 分配角色资源（权限）
	roleDel: '/czy-uaa/role/del', // 角色删除
	roleAdd: '/czy-uaa/role/add', // 角色新增
	roleUpdate: '/czy-uaa/role/update', // 角色修改
	
	elaSearch: '/czy-sms/ela/search', // 短信日志列表
};

// 也可以像下面这样，区分环境或者区分服务器等等
// let service = 'dev';
// // let service = 'prod';
// let api = '';
// if (service === 'dev') {
//   /**dev开发**/
//   api = '/stomatology/patient';
// } else if (service === 'prod') {
//   /**prod部署**/
//   api = '/proxy/client';
// }
//
// export default {
//	// 声明api url
// }
