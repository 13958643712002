import Vue from 'vue'
import VueRouter from 'vue-router'
import MenuRouter from './router';
import ViewsRouter from './views/'

// import base from '@/views/base.js'

Vue.use(VueRouter)

const router = new VueRouter({
	mode: 'history', // 设置为自定义模式
	routes: [{
		path: '/',
		redirect: '/login',
	},
	{
		path: '/login',
		component: () => import('@/views/main/login/login.vue' /* webpackChunkName: "login" */),
	},
	{
		path: '/register',
		component: () => import('@/views/main/register/register.vue' /* webpackChunkName: "register" */),
	},]
})

MenuRouter.install(router);

router.addRoutes([...ViewsRouter]);
router.$menuRouter.formatRoutes(JSON.parse(localStorage.getItem("menuList")), true);

router.beforeEach(async (to, from, next) => {
	next()
	// TODO 无登录 目前不做拦截
	// if (to.path == '/login' || to.path == '/register') {
	// 	next()
	// } else {
	// 	if (!localStorage.getItem('token')) {
	// 		next('/login')
	// 	} else {
	// 		base.setVuexResourceMenu(to.path);
	// 		window.onresize = null //清除表格高度监听
	// 		next()
	// 	}
	// }
})

export default router
