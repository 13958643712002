<template>
    <!-- <div>
        <Cascader :data="areaCode" v-model="placeholders" change-on-select filterable></Cascader>
    </div> -->
    <Cascader :data="areaCode" v-model="placeholders" change-on-select filterable @on-visible-change="onVisibleChange"></Cascader>
</template>

<script>
import areaCode from './area_code.json'
export default {
    props: {
        value: {
            type: String,
        },
    },
    components: {},
    data() {
        return {
            areaCode: areaCode,
            placeholders: [],
            valueData: '',
        }
    },
    computed: {},
    watch: {
        placeholders() {
            let res = ''
            if (this.placeholders.length) {
                res = this.placeholders.join('|')
            }
            this.$emit('input', res)
        },
        value() {
            this.placeholders = this.value ? this.value.split('|') : []
        },
        valueData() {
            this.placeholders = this.valueData ? this.valueData.split('|') : []
        },
    },
    methods: {
        onVisibleChange() {
            setTimeout(() => {
                this.$emit('on-visible-change', this.placeholders)
            }, 0)
        },
    },
    mounted() {
        this.valueData = this.value
    },
}
</script>

<style scoped>
</style>
