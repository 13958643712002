/**
 * 获取websocket链接的地址base
 */
export const getWebsocketBaseUrl = () => {
    let environment = {
        // dev: "gzjc-shop-dev.ceczy.com:7074/gz-review",
        // test: "gzjc-shop-test.ceczy.com:7075/gz-review",
        // uat: "gzjc-shop-uat.ceczy.com:9075/gz-review",
        // prod: "gzjc.ceczy.com/gz-review",
        dev: "hj.yangguangzhaocai.com/czy-busi",
        test: "hj.yangguangzhaocai.com/czy-busi",
        uat: "hj.yangguangzhaocai.com/czy-busi",
        prod: "hj.yangguangzhaocai.com//czy-busi",
    };
    let type = environment.dev;
    if (process.env.VUE_APP_ENV == 'development') {
        type = environment.dev;
    } else if (process.env.VUE_APP_ENV == 'test') {
        type = environment.test;
    } else if (process.env.VUE_APP_ENV == 'uat') {
        type = environment.uat;
    } else if (process.env.VUE_APP_ENV == 'production') {
        type = environment.prod;
    }
    return type
}