<template>
    <div class="shadowLayer">
        <slot />
    </div>
</template>

<script>
export default {
    props: {},
    components: {},
    data() {
        return {}
    },
    computed: {},
    watch: {},
    methods: {},
    mounted() {},
}
</script>

<style scoped>
.shadowLayer {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999999;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.3);
}
</style>
