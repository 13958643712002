<!-- 基本信息 -->
<template>
	<el-form ref="dataForm" :model="dataForm" :rules="rules" label-width="120px" size="small">
		<el-row>
			<el-col v-for="(item, index) in formList" :key="index" :span="item.span" :pull="item.offset" style="padding-bottom: 20px">
				<template v-if="item.slot">
					<slot :name="item.slot"></slot>
				</template>
				<el-form-item v-else-if="userTpey == 2 && item.label == '登录名'" :label="item.label" :label-width="item.labelWidth" :prop="item.key">
					<el-input v-if="item.type == 'input'" v-model="dataForm[item.key]" :placeholder="`请输入${item.label}`" clearable class="mr10" style="width: calc(100% - 50px)" disabled></el-input>

					<el-input v-if="item.type == 'password'" v-model="dataForm[item.key]" :placeholder="`请输入${item.label}`" clearable show-password class="mr10" style="width: calc(100% - 50px)"></el-input>

					<el-input
						v-else-if="item.type == 'textarea'"
						v-model="dataForm[item.key]"
						v-emoji
						type="textarea"
						:placeholder="`请输入${item.label}`"
						clearable
						class="mr10"
						maxlength="1000"
						show-word-limit
						rows="5"
						style="width: calc(100% - 50px)"
					></el-input>

					<el-select v-else-if="item.type == 'select'" v-model="dataForm[item.key]" clearable :placeholder="`请选择${item.label}`" style="width: calc(100% - 50px)">
						<el-option v-for="(item, index) in item.optList" :key="index" :label="item.dicName" :value="item.dicValue"></el-option>
					</el-select>

					<el-cascader
						v-else-if="item.type == 'cascader'"
						v-model="dataForm[item.key]"
						:props="item.props"
						:options="item.options"
						clearable
						:change-on-select="!item.showAllLevels"
						:disabled="item.disabled"
						style="width: calc(100% - 50px)"
					></el-cascader>

					<vDistpicker v-else-if="item.type == 'areaCascader'" v-model="dataForm[item.key]" style="width: calc(100% - 50px)" />

					<el-radio-group v-else-if="item.type == 'radio'" v-model="dataForm[item.key]">
						<el-radio v-for="(item, index) in item.optList" :key="index" :label="item.dicValue">{{ item.dicName }}</el-radio>
					</el-radio-group>

					<el-date-picker
						v-else-if="item.type == 'date'"
						v-model="dataForm[item.key]"
						type="date"
						:placeholder="`请选择${item.label}`"
						format="yyyy 年 MM 月 dd 日"
						value-format="yyyy-MM-dd"
						style="width: calc(100% - 50px)"
					>
					</el-date-picker>

					<fileUploadCP v-else-if="item.type == 'file'" :sourceFileList="dataForm[item.key]" :fileType="['png', 'jpeg', 'jpg']" @handleFile="(res) => $emit('handleFile', res)" />
				</el-form-item>
				<el-form-item v-else :label="item.label" :label-width="item.labelWidth" :prop="item.key">
					<el-input v-if="item.type == 'input'" v-model="dataForm[item.key]" :placeholder="`请输入${item.label}`" clearable class="mr10" style="width: calc(100% - 50px)"></el-input>

					<el-input v-if="item.type == 'password'" v-model="dataForm[item.key]" :placeholder="`请输入${item.label}`" clearable show-password class="mr10" style="width: calc(100% - 50px)"></el-input>

					<el-input
						v-else-if="item.type == 'textarea'"
						v-model="dataForm[item.key]"
						v-emoji
						type="textarea"
						:placeholder="`请输入${item.label}`"
						clearable
						class="mr10"
						maxlength="1000"
						show-word-limit
						rows="5"
						style="width: calc(100% - 50px)"
					></el-input>

					<el-select v-else-if="item.type == 'select'" v-model="dataForm[item.key]" clearable :placeholder="`请选择${item.label}`" style="width: calc(100% - 50px)">
						<el-option v-for="(item, index) in item.optList" :key="index" :label="item.dicName" :value="item.dicValue"></el-option>
					</el-select>

					<el-cascader
						v-else-if="item.type == 'cascader'"
						v-model="dataForm[item.key]"
						:props="item.props"
						:options="item.options"
						clearable
						:change-on-select="!item.showAllLevels"
						:disabled="item.disabled"
						style="width: calc(100% - 50px)"
					></el-cascader>

					<vDistpicker v-else-if="item.type == 'areaCascader'" v-model="dataForm[item.key]" style="width: calc(100% - 50px)" />

					<el-radio-group v-else-if="item.type == 'radio'" v-model="dataForm[item.key]">
						<el-radio v-for="(item, index) in item.optList" :key="index" :label="item.dicValue">{{ item.dicName }}</el-radio>
					</el-radio-group>

					<el-date-picker
						v-else-if="item.type == 'date'"
						v-model="dataForm[item.key]"
						type="date"
						:placeholder="`请选择${item.label}`"
						format="yyyy 年 MM 月 dd 日"
						value-format="yyyy-MM-dd"
						style="width: calc(100% - 50px)"
					>
					</el-date-picker>

					<fileUploadCP v-else-if="item.type == 'file'" :sourceFileList="dataForm[item.key]" :fileType="['png', 'jpeg', 'jpg']" @handleFile="(res) => $emit('handleFile', res)" />
				</el-form-item>
			</el-col>
		</el-row>
	</el-form>
</template>
<script>
export default {
	components: {},
	name: '',
	props: {
		dataForm: {
			type: Object,
			default: () => {}
		},
		formList: [],
		rules: {
			type: Object,
			default: () => {}
		},
		userTpey: {
			type: String,
			default: ''
		}
	},
	data() {
		return {}
	},
	//监听属性 类似于data概念
	computed: {},
	//监控data中的数据变化
	watch: {},
	//方法集合
	methods: {},
	//生命周期 - 创建完成（可以访问当前this实例）
	created() {},
	//生命周期 - 挂载完成（可以访问DOM元素）
	mounted() {},
	//生命周期 - 创建之前
	beforeCreate() {},
	//生命周期 - 挂载之前
	beforeMount() {},
	//生命周期 - 更新之前
	beforeUpdate() {},
	//生命周期 - 更新之后
	updated() {},
	//生命周期 - 销毁之前
	beforeDestroy() {},
	//生命周期 - 销毁完成
	destroyed() {},
	//如果页面有keep-alive缓存功能，这个函数会触发
	activated() {}
}
</script>
<style lang="less" scoped>
//@import url(); 引入公共css类
.el-form-item {
	margin-bottom: 0;
}
</style>
<style>
.form-group:before {
	content: '* ';
	color: red;
}
</style>
