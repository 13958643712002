<!-- 上传文件公共组件 -->
<!-- 一共接收五个值,见props -->
<!-- 使用方式 -->
<!-- <fileUploadCP :sourceFileList="fileList" :fileSize="100" :limit="1"
      :fileType="['doc','docx']" readonly/> -->
<template>
	<div class="">
		<template v-if="!readonly">
			<el-upload
				class="upload-demo"
				action="/czy-file/file/upload"
				:on-preview="handlePreview"
				:on-remove="(file, fileList) => handleRemove(file, fileList, sourceFileList)"
				:limit="limit"
				:on-exceed="(files, fileList) => handleExceed(files, fileList, limit)"
				:accept="accept"
				:on-success="(res, file) => handleAvatarSuccess(res, file, sourceFileList)"
				:file-list="sourceFileList"
				:before-upload="(file) => beforeAvatarUpload(file, fileType, fileSize)"
				:headers="{ Authorization: token }"
			>
				<el-button size="small" type="primary">选取文件</el-button>
				<div slot="tip" class="el-upload__tip_CCQ mt10">
					{{ tip }}
				</div>
			</el-upload>
		</template>
		<template v-else>
			<div v-for="(item, index) in sourceFileList" :key="index">
				<el-link type="primary" target="_blank" :href="item.fileUrl"> {{ item.fileName }}</el-link>
			</div>
		</template>
	</div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
	//import引入的组件需要注入到对象中才能使用
	components: {},
	//只有作为组件选项时起作用
	name: '',
	//这里存放父组件的传值
	props: {
		//文件list对象,必传,必须为数组,在父组件就修改好
		sourceFileList: {
			type: Array,
			default: () => {
				return []
			}
		},
		//文件类型,可不传,默认不限制,数组格式如 ['pdf','png',]
		fileType: {
			type: Array,
			default: () => {
				return []
			}
		},
		//文件大小,可不传,默认单个文件100M
		fileSize: {
			type: Number,
			default: 100
		},
		//文件最大上传数量,可不传,默认1个
		limit: {
			type: Number,
			default: 1
		},
		// 是否文件只读（不增删）,可不传,默认可操作
		readonly: {
			type: Boolean,
			default: false
		}
	},
	data() {
		//这里存放数据
		return {
			token: localStorage.getItem('token'),
			tip: '',
			accept: ''
		}
	},
	//监听属性 类似于data概念
	computed: {},
	//监控data中的数据变化
	watch: {
		limit: function (val) {
			this.tip = this.getTip(this.fileType)
		}
	},
	//方法集合
	methods: {
		handleRemove(file, fileList, list) {
			for (let i = 0; i < list.length; i++) {
				const e = list[i]
				if (e.uid == file.uid) {
					this.deleteRow(i, list)
				}
			}
		},
		deleteRow(index, list) {
			list.splice(index, 1)
			this.$emit('handleFile', list)
		},
		handlePreview(file) {
			window.open(file.fileUrl, '_blank')
		},
		handleExceed(files, fileList, num) {
			this.$message.warning(`当前限制选择 ${num} 个文件,重新上传需删除当前文件`)
			// this.$message.warning(
			//   `当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
			//     files.length + fileList.length
			//   } 个文件`
			// );
		},
		beforeAvatarUpload(file, fileType, size) {
			let index = file.name.split('.').length - 1
			let name = file.name.split('.')[index].toLowerCase()
			if (fileType.length > 0 && fileType.indexOf(name) < 0) {
				let typeVal = ''
				fileType.forEach((e) => {
					typeVal = `*.${e};` + typeVal
				})
				this.$message.warning(`您上传的文件格式不合规,请上传${typeVal}格式`)
				return false
			}
			if (file.name.length > 50) {
				this.$message.warning(`文件名过长，请修改后上传`)
				return false
			}
			if (file.size / 1024 / 1024 > size) {
				this.$message.warning(`${file.name}，大小已超过${size}M，无法上传~`)
				return false
			}
		},
		handleAvatarSuccess(res, file, list) {
			let obj = {
				ossId: res.body.data.ossId,
				fileUrl: res.body.data.url,
				name: res.body.data.fileName,
				fileName: res.body.data.fileName
			}
			list.push(obj)
			this.$emit('handleFile', list)
		},
		getaccept(list) {
			if (list.length == 0) {
				return '*'
			} else {
				let newList = JSON.parse(JSON.stringify(list))
				for (let i = 0; i < newList.length; i++) {
					newList[i] = '.' + newList[i]
				}
				return newList.join(',')
			}
		},
		getTip(list) {
			if (list.length == 0) {
				return `最多上传${this.limit}个文件(不限格式)，且单个文件不超过${this.fileSize}M`
			} else {
				let newList = JSON.parse(JSON.stringify(list))
				for (let i = 0; i < newList.length; i++) {
					newList[i] = '*.' + newList[i]
				}
				return `最多上传${this.limit}个文件(${newList.join(',')}格式)，且单个文件不超过${this.fileSize}M`
			}
		}
	},
	//生命周期 - 创建完成（可以访问当前this实例）
	created() {
		this.accept = this.getaccept(this.fileType)
		this.tip = this.getTip(this.fileType)
	},
	//生命周期 - 挂载完成（可以访问DOM元素）
	mounted() {},
	//生命周期 - 创建之前
	beforeCreate() {},
	//生命周期 - 挂载之前
	beforeMount() {},
	//生命周期 - 更新之前
	beforeUpdate() {},
	//生命周期 - 更新之后
	updated() {},
	//生命周期 - 销毁之前
	beforeDestroy() {},
	//生命周期 - 销毁完成
	destroyed() {},
	//如果页面有keep-alive缓存功能，这个函数会触发
	activated() {}
}
</script>
<style lang="less" scoped>
//@import url(); 引入公共css类
.el-upload__tip_CCQ {
	color: #b1b1b1;
	font-size: 12px;
	line-height: 16px;
}
</style>
