<!-- 流转日志 -->
<template>
    <div class=''>
        <titleCP label="流转日志" needShow style="text-align:left">
            <el-timeline v-if="activities.length > 0" reverse>
                <el-timeline-item v-for="(activity, index) in activities" :key="index" :timestamp="activity.operTime"
                    :type="'primary'">
                    <span class="mr5">{{ activity.operName }}</span>
                    <span class="mr5">{{ activity.sysName }}</span>
                    <span class="mr5">{{ activity.actionName }}</span>
                    <span class="mr5">
                        <span v-if="activity.actionResult == '01'">通过</span>
                        <span v-if="activity.actionResult == '02'">不通过</span>
                    </span>
                    <span class="mr5">{{ activity.actionAdvise }}</span>
                </el-timeline-item>
            </el-timeline>
            <div v-else>
                <el-empty description="暂无数据"></el-empty>
            </div>
        </titleCP>
    </div>
</template>

<script>
import { listProjectLogByBusi } from "@/axios/api/forCCQ/entrustPurchase/purchaseDocumentConfirm.js";
export default {
    components: {},
    name: "",
    props: {
        id: {},
        busiType: {},
    },
    data() {
        return {
            activities: [],
        };
    },
    computed: {},
    watch: {
        id() {
            this.listProjectLogByBusi();
        },
    },
    methods: {
        listProjectLogByBusi() {
            if (this.id) {
                listProjectLogByBusi({
                    busiId: this.id,
                    busiType: this.busiType,
                }).then((res) => {
                    this.activities = res;
                    this.$emit('handleData', this.activities)
                }).catch((err) => {
                    console.log(err);
                });
            }
        },
    },
    created() {
        this.listProjectLogByBusi();
    },
    //生命周期 - 挂载完成（可以访问DOM元素）
    mounted() { },
    //生命周期 - 创建之前
    beforeCreate() { },
    //生命周期 - 挂载之前
    beforeMount() { },
    //生命周期 - 更新之前
    beforeUpdate() { },
    //生命周期 - 更新之后
    updated() { },
    //生命周期 - 销毁之前
    beforeDestroy() { },
    //生命周期 - 销毁完成
    destroyed() { },
    //如果页面有keep-alive缓存功能，这个函数会触发
    activated() { },
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
</style>