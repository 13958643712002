import Layout from '@/views/frame'
export default [
    {
        path: '/workbench',
        component: Layout,
        redirect: '/workbench/agency',
        children: [{
            path: 'agency',
            name: '代理机构首页',
            component: () => import('@/views/workbench/agency/index')
        }, {
            path: 'provider',
            name: '供应商首页',
            component: () => import('@/views/workbench/provider/index')
        }, {
            path: 'purchaser',
            name: '采购人首页',
            component: () => import('@/views/workbench/purchaser/index')
        }, {
            path: 'superviseCockpit',
            name: '监管机构首页',
            component: () => import('@/views/workbench/supervise/superviseCockpit/index')
        }, {
            path: '/purchaseProjectManage/projectGeneration',//首页查看页面
            name: '项目生成',
            component: () => import('@/views/purchaseProjectManage/projectGeneration/index.vue'),
        },]
    }, {
        path: '/purchaseProjectManage',//专家账号登录默认显示评标页面
        component: Layout,
        redirect: '/purchaseProjectManage/bidEvaluation',
        children: [{
            path: 'bidEvaluation',
            name: '评标',
            component: () => import('@/views/purchaseProjectManage/bidEvaluation/index.vue'),
        }]
    }, {
        path: '/bidTenderHj',//投标列表单点登录
        component: Layout,
        children: [{
            path: '',
            name: '投标',
            component: () => import('@/views/bidTender_hj/index.vue'),
            meta: {
                leftMenuShow: false,
            }
        }]
    }, {
        path: '/bidOpeningHall_hj',//供应商投标大厅列表单点登录
        component: Layout,
        children: [{
            path: '',
            name: '供应商投标大厅',
            component: () => import('@/views/bidOpeningHall_hj/index.vue'),
            meta: {
                leftMenuShow: false,
                leftHaderShow: false,
            }
        }]
    }, {
        path: '/bidOpeningHall',//供应商开标大厅列表
        component: Layout,
        children: [{
            path: '',
            name: '供应商开标大厅',
            component: () => import('@/views/bidOpeningHall/bidOpening/index.vue'),

        }]
    },
    {
        path: '/projectRegistration',//测试供应商需求公示
        component: Layout,
        children: [{
            path: '',
            name: '需求公示响应',
            component: () => import('@/views/participateBid/projectRegistration/index.vue'),
        }]
    },
    {
        path: '/demandPublicitySingleSignOn_hj',//测试供应商需求公示
        component: Layout,
        children: [{
            path: '',
            name: '需求公示响应',
            component: () => import('@/views/participateBid/projectRegistration/demandPublicitySingleSignOn.vue'),
            meta: {
                leftMenuShow: false,
                leftHaderShow: false,
            }
        }]
    },
    {
        path: '/hjGatheringSingleSignOn',//汇聚单点登录
        component: Layout,
        children: [{
            path: '',
            name: '汇聚单点登录',
            component: () => import('@/views/hjGatheringSingleSignOn/index.vue'),
            meta: {
                leftMenuShow: true,
                leftHaderShow: true,
            }
        }]
    },
]