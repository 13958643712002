<template>
	<div class="header clear-float">
		<a class="logo">
			<img class="img" src="./image/header.png" />
			<span class="line">|</span>
			<span class="text">{{ userInfo.enterpriseName }}</span>
		</a>
		<div class="user">
			<!-- <el-button v-if="leftMenuShow" class="color-white entity-type" type="text" @click="handleToWorkbench">
				<i class="el-icon-monitor mr5" size="20"></i>工作台
			</el-button> -->
			<p class="entity-type">
				<span class="system-entity">{{ operatorTypeOpts[userInfo.operatorType] }} | {{ userInfo.name }}</span>
			</p>
		</div>
		<resetPassword ref="resetPassword"></resetPassword>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import { removeToken } from '@/axios/api/topHeader.js'
import resetPassword from '@/views/main/login/vue/resetPassword.vue'
export default {
	components: { resetPassword },
	data() {
		return {
			leftMenuShow: true, //从汇聚客户端进入项目的单页面
			poptipVisible: false,
			operatorTypeOpts: ['普通用户', '超级管理员', '区划管理员', '专家', '供应商', '代理机构管理', '采购人', '监管机构'],
			userInfo: JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo')) : {}
		}
	},
	computed: {
		...mapState(['systemEntity', 'options'])
	},
	methods: {
		init() {
			this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
		},

		handleToWorkbench() {
			if (this.$route.path.includes('/workbench')) {
				this.$router.go(0)
				return
			}
			if (this.userInfo.operatorType == '4') {
				this.$router.push({ path: '/workbench/provider' })
			} else if (this.userInfo.operatorType == '0' || this.userInfo.operatorType == '5') {
				this.$router.push({ path: '/workbench/agency' })
			} else if (this.userInfo.operatorType == '6') {
				this.$router.push({ path: '/workbench/purchaser' })
			} else {
				this.$router.push({ path: '/workbench/agency' })
			}
		}
	},
	created() {
		this.leftMenuShow = this.$route.meta && this.$route.meta.leftMenuShow == false ? this.$route.meta.leftMenuShow : true
	},
	mounted() {}
}
</script>

<style scoped>
a,
a:hover {
	color: #fff;
}

.header {
	position: relative;
	padding: 0 20px;
	height: 56px;
	line-height: 56px;
	background-color: #1f293d;
	/* background-color: #0266be; */
}

.header > .logo > .line {
	padding: 0 12px;
	color: #ffffff;
}

.header > .logo > .text {
	color: #ffffff;
	font-size: 16px;
	font-weight: 500;
}

.header > .logo > .img {
	margin-top: 15px;
	vertical-align: top;
}

.header > .user {
	float: right;
	cursor: pointer;
	color: #ffffff;
}

.header > .user .entity-type {
	margin-right: 20px;
	display: inline-block;
}

.header > .user .entity-type .system-entity {
	margin-right: 5px;
	font-size: 12px;
}

.color-white {
	color: white;
}
</style>
