<template>
    <Modal @on-cancel="cancel" v-model="showModal" :title="fileName" width="1000">
        <div v-if="showModal" style="text-align: center;">
            <img :src="src" style="max-width: 100%;" />
        </div>
        <div slot="footer">
            <Button type="primary" @click="ok">确定</Button>
        </div>
    </Modal>
</template>
<script>
export default {
    components: {},
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        info: {
            type: Object,
            default: () => {
                return {}
            },
        },
    },
    data() {
        return {
            src: '',
            fileName: '',
        }
    },
    computed: {
        showModal: {
            get() {
                return this.value
            },
            set(newVal) {
                this.$emit('input', newVal)
            },
        },
    },
    watch: {
        showModal() {
            if (this.showModal) {
                this.fileName = this.info.fileName
                this.src = this.info.url
            }
        },
    },
    methods: {
        async ok() {
            this.cancel()
        },
        cancel() {
            this.showModal = false
            this.$emit('update:info', {})
        },
    },
    mounted() {},
}
</script>
<style scoped>
</style>
