<template>
    <div class="i-Table">
        <loading v-if="loading" />
        <Table v-bind="customTableSettings"></Table>
        <div style="margin: 10px; overflow: hidden">
            <div style="float: right">
                <Page v-if="pagination" show-total show-elevator show-sizer :total="Number(total)" :current="pageIndexSync" :pageSize="pageSizeSync" :pageSizeOpts="pageSizeOpts" @on-change="changePage" @on-page-size-change="pageSizeChange"></Page>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        loading: {
            type: Boolean,
            default: false,
        },
        tableSettings: {
            type: Object,
        },
        total: {
            type: Number,
        },
        pageIndex: {
            type: Number,
            default: 1,
        },
        pageSize: {
            type: Number,
            default: 10,
        },
        pageSizeOpts: {
            type: Array,
            default: () => {
                return [10, 20, 30, 40]
            },
        },
        pagination: {
            type: Boolean,
            default: false,
        },
        servePageFun: {
            type: Function,
        },
    },
    data() {
        return {
            pageIndexSync: this.pageIndex,
            pageSizeSync: this.pageSize,
        }
    },
    computed: {
        customTableSettings() {
            return lodash.assignInWith(this.tableSettings, {
                border: true,
            })
        },
    },
    watch: {
        pageIndexSync() {
            this.$emit('update:pageIndex', this.pageIndexSync)
        },
        pageSizeSync() {
            this.$emit('update:pageSize', this.pageSizeSync)
        },
        servePageFun: {
            immediate: true,
            handler() {
                if (!this.servePageFun) {
                    return
                }
                this.servePageFun(this.pageIndexSync, this.pageSizeSync)
            },
        },
    },
    methods: {
        changePage(pageIndex) {
            this.pageIndexSync = pageIndex
            this.servePageFun(this.pageIndexSync, this.pageSizeSync)
        },
        pageSizeChange(pageSize) {
            this.pageIndexSync = 1
            this.pageSizeSync = pageSize
            this.servePageFun(this.pageIndexSync, this.pageSizeSync)
        },
    },
    mounted() {},
}
</script>
<style scoped>
.i-Table {
    position: relative;
    width: 100%;
    clear: both;
}
.i-Table >>> .ivu-table td,
.ivu-table th {
    height: 40px;
}
.i-Table >>> .ivu-table-cell {
    padding-left: 10px;
    padding-right: 10px;
}
</style>
