import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    resource: {
      treeList: [],
    },
    menu: {
      menuList: [
        /* 示例 start */
        {
          name: '菜单管理',
          route: '/czy/system/menu',
          icon: null,
          open: false,
          child: [],
        },
        {
          name: '主体管理',
          route: null,
          icon: 'md-settings',
          open: false,
          child: [
           
            {
              name: '供应商管理',
              route: '/czy/system/mainBody/providerManage',
              icon: null,
              open: false,
              child: [],
            },
            {
              name: '代理机构管理',
              route: '/czy/system/mainBody/orgManage',
              icon: null,
              open: false,
            },
            {
              name: '采购人管理',
              route: '/czy/system/mainBody/unitManage',
              icon: null,
              open: false,
            },
            {
              name: '监管机构管理',
              route: '/czy/system/mainBody/regulatorManage',
              icon: null,
              open: false,
            },
            {
              name: '主体菜单管理',
              route: '/czy/system/mainBody/menuManage',
              icon: null,
              open: false,
            },
          ],
        },
        {
          name: '区划管理',
          route: null,
          icon: 'md-settings',
          open: false,
          child: [
            {
              name: '区划设置',
              route: '/czy/regionalAllocation/regionalization',
              icon: null,
              open: false,
              child: [],
            },
            {
              name: '区划管理员',
              route: '/czy/regionalAllocation/districtManager',
              icon: null,
              open: false,
            },
          ],
        },
        {
          name: '采购项目管理',
          route: null,
          icon: 'md-settings',
          open: false,
          child: [
            {
              name: '开标/评标',
              route: '/czy/purchaseProjectManage/bidOpening',
              icon: '',
              open: false,
            },
            {
              name: '评标',
              route: '/czy/purchaseProjectManage/bidEvaluation',
              icon: '',
              open: false,
            },
            {
              name: '重评',
              route: '/czy/purchaseProjectManage/reevaluate',
              icon: '',
              open: false,
            },
            {
              name: '需求公示',
              route: '/czy/purchaseProjectManage/demandFormula',
              icon: '',
              open: false,
            },
            {
              name: '需求公示审核',
              route: '/czy/purchaseProjectManage/demandFormulaAudit',
              icon: '',
              open: false,
            },
            {
              name: '项目生成',
              route: '/czy/purchaseProjectManage/projectGeneration',
              icon: '',
              open: false,
            },
            {
              name: '采购文件制作',
              route: '/czy/purchaseProjectManage/procurementDocumentMake',
              icon: null,
              open: false,
            },
            {
              name: '采购文件批注',
              route: '/czy/purchaseProjectManage/documentAnnotation',
              icon: null,
              open: false,
            },
            {
              name: '采购文件下载',
              route: '/czy/purchaseProjectManage/fileDownload',
              icon: null,
              open: false,
            },
            {
              name: '采购文件上传',
              route: '/czy/purchaseProjectManage/fileUpload',
              icon: null,
              open: false,
            },
            {
              name: '内审意见统计',
              route: '/czy/purchaseProjectManage/opinionStatistics',
              icon: null,
              open: false,
            },
            {
              name: '采购文件审核',
              route: '/czy/purchaseProjectManage/fileAudit',
              icon: null,
              open: false,
            },
            {
              name: '采购公告发布',
              route: '/czy/purchaseProjectManage/announceRelease',
              icon: null,
              open: false,
            },
            {
              name: '项目登记及审核',
              route: '/czy/purchaseProjectManage/registrationAudit',
              icon: null,
              open: false,
            },
            {
              name: '采购报告制作',
              route: '/czy/purchaseProjectManage/PurchaseReportMake',
              icon: null,
              open: false,
            },

            {
              name: '采购报告审核',
              route: '/czy/purchaseProjectManage/PurchaseReportAudit',
              icon: null,
              open: false,
            },
            {
              name: '采购报告退回审核',
              route: '/czy/purchaseProjectManage/PurchaseReportBackAudit',
              icon: null,
              open: false,
            },
            {
              name: '中标通知书管理',
              route: '/czy/purchaseProjectManage/bidNotificationManage',
              icon: null,
              open: false,
            },
            {
              name: '中标通知书审核',
              route: '/czy/purchaseProjectManage/bidNotificationAudit',
              icon: null,
              open: false,
            },
            {
              name: '结果公告发布',
              route: '/czy/purchaseProjectManage/resultAnnouncePublish',
              icon: null,
              open: false,
            },
            {
              name: '采购中心审核列表',
              route: '/czy/purchaseProjectManage/purchaseAuditList',
              icon: null,
              open: false,
            },
            {
              name: '专家申请',
              route: '/czy/purchaseProjectManage/expertApplication',
              icon: null,
              open: false,
            },
            {
              name: '专家申请确定',
              route: '/czy/purchaseProjectManage/expertApplicationConfirm',
              icon: null,
              open: false,
            },
          ],
        },
        {
          name: '澄清与质疑管理',
          route: null,
          icon: 'md-settings',
          open: false,
          child: [
            {
              name: '采购文件质疑回复',
              route: '/czy/clarifyAndQuestion/fileChallengeAccept',
              icon: null,
              open: false,
              child: [],
            },
            {
              name: '采购过程质疑回复',
              route: '/czy/clarifyAndQuestion/processChallengeAccept',
              icon: null,
              open: false,
              child: [],
            },
            {
              name: '采购结果质疑回复',
              route: '/czy/clarifyAndQuestion/resultChallengeAccept',
              icon: null,
              open: false,
              child: [],
            },
            {
              name: '质疑审核',
              route: '/czy/clarifyAndQuestion/auditAccept',
              icon: null,
              open: false,
              child: [],
            },
            {
              name: '招标问题回复',
              route: '/czy/clarifyAndQuestion/replyToTenderQuestion',
              icon: null,
              open: false,
              child: [],
            },
            {
              name: '质疑管理',
              route: '/czy/clarifyAndQuestion/questioningManagement',
              icon: null,
              open: false,
              child: [],
            },
          ],
        },
        {
          name: '电子归档',
          route: '/czy/electronicFiling/index',
          icon: null,
          open: false,
        },
        {
          name: '项目统计及查询',
          route: null,
          icon: 'md-settings',
          open: false,
          child: [
            {
              name: '项目查询',
              route: '/czy/projectStatisticsInquiry/itemQuery',
              icon: '',
              open: false,
            },
            {
              name: '数据上报模型',
              route: '/czy/projectStatisticsInquiry/dataReportingModel',
              icon: '',
              open: false,
            },
            {
              name: '财政项目统计',
              route: '/czy/projectStatisticsInquiry/financialStatistics',
              icon: '',
              open: false,
            },
            {
              name: '项目检索',
              route: '/czy/projectStatisticsInquiry/projectRetrieval',
              icon: '',
              open: false,
            },
            {
              name: '计划执行情况统计',
              route:
                '/czy/projectStatisticsInquiry/planImplementationStatistics',
              icon: '',
              open: false,
            },
            {
              name: '供应商分析',
              route: '/czy/projectStatisticsInquiry/supplierAnalysis',
              icon: '',
              open: false,
            },
            {
              name: '专家参与分析',
              route:
                '/czy/projectStatisticsInquiry/expertParticipationAnalysis',
              icon: '',
              open: false,
            },
          ],
        },
        {
          name: '合同管理',
          route: null,
          icon: 'md-settings',
          open: false,
          child: [
            {
              name: '合同签订',
              route: '/czy/contractManagement/contractSigning',
              icon: null,
              open: false,
              child: [],
            },
            {
              name: '合同公示',
              route: '/czy/contractManagement/contractPublicity',
              icon: null,
              open: false,
              child: [],
            },
            {
              name: '供应商合同签订',
              route: '/czy/contractManagement/contractSigningSupplier',
              icon: null,
              open: false,
              child: [],
            },
          ],
        },
        {
          name: '基础配置',
          route: null,
          icon: 'md-settings',
          open: false,
          child: [
            {
              name: '电子证书设置',
              route: '/czy/basicConfig/eCertSet',
              icon: null,
              open: false,
            },
            {
              name: '计量单位录入',
              route: '/czy/basicConfig/unitEntry',
              icon: null,
              open: false,
            },
            {
              name: '采购类别',
              route: '/czy/basicConfig/purchasingClass',
              icon: null,
              open: false,
            },
            {
              name: '供应商资格库维护',
              route: '/czy/basicConfig/qualificationBank',
              icon: null,
              open: false,
            },
            {
              name: '敏感词管理',
              route: '/czy/basicConfig/sensitiveWord',
              icon: null,
              open: false,
            },
          ],
        },
        {
          name: '差异化配置',
          route: null,
          icon: 'md-settings',
          open: false,
          child: [
            {
              name: '差异功能设置',
              route: '/czy/differentiatedAllocation/differentialFunctionSet',
              icon: null,
              open: false,
            },
            {
              name: '差异流程设置',
              route: '/czy/differentiatedAllocation/differentialFlowSet',
              icon: null,
              open: false,
            },
          ],
        },
        {
          name: '模板管理',
          route: null,
          icon: 'md-settings',
          open: false,
          child: [
            {
              name: '开标一览表列表',
              route: '/czy/templateManagement/listOfBidOpening',
              icon: null,
              open: false,
            },
            {
              name: '评标方法模板',
              route: '/czy/templateManagement/bidEvaluation',
              icon: null,
              open: false,
            },
            {
              name: '资料审查模板',
              route: '/czy/templateManagement/dataReview',
              icon: null,
              open: false,
            },
            {
              name: '资格审查模板',
              route: '/czy/templateManagement/qualificationExamination',
              icon: null,
              open: false,
            },
            {
              name: '符合审查模板',
              route: '/czy/templateManagement/complianceReview',
              icon: null,
              open: false,
            },
            {
              name: '评分公式模板',
              route: '/czy/templateManagement/scoringFormula',
              icon: null,
              open: false,
            },
            {
              name: '响应文件模板',
              route: '/czy/templateManagement/responseFile',
              icon: null,
              open: false,
            },
            {
              name: '公告模板',
              route: '/czy/templateManagement/announcement',
              icon: null,
              open: false,
            },
            {
              name: '委托协议模板',
              route: '/czy/templateManagement/commissionAgreement',
              icon: null,
              open: false,
            },
            {
              name: '通知书模板',
              route: '/czy/templateManagement/notice',
              icon: null,
              open: false,
            },
            {
              name: '通知书模板-代理机构',
              route: '/czy/templateManagement/noticeDLJG',
              icon: null,
              open: false,
            },
            {
              name: '框架协议淘汰原因模板',
              route: '/czy/templateManagement/reason',
              icon: null,
              open: false,
            },
          ],
        },
        {
          name: '审批管理平台',
          route: null,
          icon: 'md-settings',
          open: false,
          child: [
            {
              name: '交易系统审核',
              route: '/czy/examineManage/transactionReview',
              icon: null,
              open: false,
            },
          ],
        },
        {
          name: '系统设置',
          route: null,
          icon: 'md-settings',
          open: false,
          child: [
            {
              name: '个人信息',
              route: '/czy/system/personalInfo',
              icon: null,
              open: false,
              }, 
              // {
              // 	name: '账户管理',
              // 	route: '/czy/system/account',
              // 	icon: null,
              // 	open: false,
              // }, 
              {
              	name: '角色管理',
              	route: '/czy/system/role',
              	icon: null,
              	open: false,
              },
              {
              	name: '部门管理',
              	route: '/czy/system/dept',
              	icon: null,
              	open: false,
              },
              {
              	name: '用户管理',
              	route: '/czy/system/user',
              	icon: null,
              	open: false,
              },
            //    {
            //   	name: '短信日志',
            //   	route: '/czy/system/messageLog',
            //   	icon: '',
            //   	open: false
            // },
          ],
        },
        {
          name: '日志中心',
          route: null,
          icon: 'md-settings',
          open: false,
          child: [
            {
              name: '登录日志',
              route: '/czy/logCenter/loginLog',
              icon: null,
              open: false,
            },
            {
              name: '文件日志',
              route: '/czy/logCenter/fileLog',
              icon: '',
              open: false,
            },
          ],
        },
        {
          name: '参与投标',
          route: null,
          icon: 'md-settings',
          open: false,
          child: [
            {
              name: '下载标书',
              route: '/czy/participateBid/downloadTender',
              icon: null,
              open: false,
            },
            {
              name: '项目登记',
              route: '/czy/participateBid/projectRegistration',
              icon: '',
              open: false,
            },
            {
              name: '在线投标',
              route: '/czy/participateBid/bidOnline',
              icon: '',
              open: false,
            },
            {
              name: '最终报价',
              route: '/czy/participateBid/finalOffer',
              icon: '',
              open: false,
            },
            {
              name: '中标通知书下载',
              route: '/czy/participateBid/bidNoticeDownload',
              icon: '',
              open: false,
            },
            {
              name: '未中标通知书下载',
              route: '/czy/participateBid/unBidNoticeDownload',
              icon: '',
              open: false,
            },
          ],
        },
        {
          name: '委托采购',
          route: null,
          icon: 'md-settings',
          open: false,
          child: [
            {
              name: '计划录入',
              route: '/czy/entrustPurchase/planEntry',
              icon: null,
              open: false,
            },
            {
              name: '委托协议签订',
              route: '/czy/entrustPurchase/signAgreement',
              icon: '',
              open: false,
            },
            {
              name: '需求委托',
              route: '/czy/entrustPurchase/demandEntrust',
              icon: '',
              open: false,
            },
            {
              name: '采购文件确认',
              route: '/czy/entrustPurchase/purchaseDocumentConfirm',
              icon: '',
              open: false,
            },
            {
              name: '采购结果确认',
              route: '/czy/entrustPurchase/purchaseResultConfirm',
              icon: '',
              open: false,
            },
            {
              name: '采购结果查看',
              route: '/czy/entrustPurchase/purchaseResultCheck',
              icon: '',
              open: false,
            },
            {
              name: '采购文件需求沟通',
              route: '/czy/entrustPurchase/purchaseFileDemandChat',
              icon: '',
              open: false,
            },
            {
              name: '中标通知书确认',
              route: '/czy/entrustPurchase/bidNotificationConfirm',
              icon: '',
              open: false,
            },
            {
              name: '评委授权函/说明函录入',
              route: '/czy/entrustPurchase/letterEntry',
              icon: '',
              open: false,
            },
            {
              name: '资格性审查',
              route: '/czy/entrustPurchase/qualificationExamination',
              icon: '',
              open: false,
            },
          ],
        },
        {
          name: '委托代理合同',
          icon: 'md-settings',
          open: false,
          route: '/czy/entrustAgencyContract',
        },
        {
          name: '项目受理及分派',
          route: null,
          icon: 'md-settings',
          open: false,
          child: [
            {
              name: '需求受理列表',
              route: '/czy/projectAcceptDispatch/demandAcceptList',
              icon: null,
              open: false,
            },
            {
              name: '部门分派',
              route: '/czy/projectAcceptDispatch/departmentDispatch',
              icon: '',
              open: false,
            },
            {
              name: '经办人分派',
              route: '/czy/projectAcceptDispatch/agentDispatch',
              icon: '',
              open: false,
            },
          ],
        },
        {
          name: '征集-采购文件确定',
          route: '/czy/collectPurchaseFiles',
          icon: 'md-settings',
          open: false,
        },
        {
          name: '征集-供应商资料审核',
          route: '/czy/supplierDataAudit',
          icon: 'md-settings',
          open: false,
        },
        {
          name: '询标记录',
          route: '/czy/bidRecord',
          icon: 'md-settings',
          open: false,
        },
        {
          name: '实质性条款变动',
          route: '/czy/materialTermsChange',
          icon: 'md-settings',
          open: false,
        },
        {
          name: '供应商框架协议签订',
          route: '/czy/gysFrameAgreementSign',
          icon: 'md-settings',
          open: false,
        },
        {
          name: '采购人框架协议签订',
          route: '/czy/cgrFrameAgreementSign',
          icon: 'md-settings',
          open: false,
        },
        {
          name: '代理机构框架协议签订',
          route: '/czy/dljgFrameAgreementSign',
          icon: 'md-settings',
          open: false,
        },
        {
          name: '入围信息公告',
          route: '/czy/finalistAnnouncement',
          icon: 'md-settings',
          open: false,
        },
        {
          name: '采购人评价管理',
          icon: 'md-settings',
          open: false,
          child: [
            {
              name: '采购人-代理机构评价',
              route: '/czy/PurchaserEvalManage/agency',
              icon: '',
              open: false,
            },
            {
              name: '采购人-专家评价',
              route: '/czy/PurchaserEvalManage/expert',
              icon: '',
              open: false,
            },
            {
              name: '采购人-供应商评价',
              route: '/czy/PurchaserEvalManage/provider',
              icon: '',
              open: false,
            },
          ],
        },
        {
          name: '代理机构评价管理',
          icon: 'md-settings',
          open: false,
          child: [
            {
              name: '代理-采购人评价',
              route: '/czy/agencyEvalManage/purchaser',
              icon: '',
              open: false,
            },
            {
              name: '代理-专家评价',
              route: '/czy/agencyEvalManage/expert',
              icon: '',
              open: false,
            },
            {
              name: '代理-供应商评价',
              route: '/czy/agencyEvalManage/provider',
              icon: '',
              open: false,
            },
          ],
        },
        {
          name: '专家评价管理',
          icon: 'md-settings',
          open: false,
          child: [
            {
              name: '专家-采购人评价',
              route: '/czy/expertEvalManage/purchaser',
              icon: '',
              open: false,
            },
            {
              name: '专家-代理机构评价',
              route: '/czy/expertEvalManage/agency',
              icon: '',
              open: false,
            },
          ],
        },
        {
          name: '供应商评价管理',
          icon: 'md-settings',
          open: false,
          child: [
            {
              name: '供应商-采购人评价',
              route: '/czy/providerEvalManage/purchaser',
              icon: '',
              open: false,
            },
            {
              name: '供应商-代理机构评价',
              route: '/czy/providerEvalManage/agency',
              icon: '',
              open: false,
            },
          ],
        },
        {
          name: '特殊情况处理',
          route: null,
          icon: 'md-settings',
          open: false,
          child: [
            {
              name: '废标公告制作',
              route: '/czy/specialCaseTreatment/bidNoticeMake',
              icon: '',
              open: false,
            },
            {
              name: '废标公告审核',
              route: '/czy/specialCaseTreatment/bidNoticeReview',
              icon: '',
              open: false,
            },
            {
              name: '需求公示延期',
              route: '/czy/specialCaseTreatment/demandPostponement',
              icon: '',
              open: false,
            },
            {
              name: '需求公示延期审核',
              route: '/czy/specialCaseTreatment/demandPostponementReview',
              icon: '',
              open: false,
            },
            {
              name: '需求公示废包公告',
              route: '/czy/specialCaseTreatment/demandAbolish',
              icon: '',
              open: false,
            },
            {
              name: '需求公示废包审核',
              route: '/czy/specialCaseTreatment/demandAbolishReview',
              icon: '',
              open: false,
            },
            {
              name: '更正公告制作',
              route: '/czy/specialCaseTreatment/correctionAnnounceMake',
              icon: null,
              open: false,
              child: [],
            },
            {
              name: '更正公告审核',
              route: '/czy/specialCaseTreatment/correctionAnnounceAudit',
              icon: null,
              open: false,
              child: [],
            },
            {
              name: '重评申请',
              route: '/czy/specialCaseTreatment/reappraisalApplication',
              icon: null,
              open: false,
              child: [],
            },
            {
              name: '重评审核',
              route: '/czy/specialCaseTreatment/reappraisalAudit',
              icon: null,
              open: false,
              child: [],
            },
            {
              name: '投标文件异常',
              route: '/czy/specialCaseTreatment/bidFileAbnormal',
              icon: '',
              open: false,
            },
            {
              name: '质疑情况查看',
              route: '/czy/clarifyAndQuestion/challengeReview',
              icon: null,
              open: false,
              child: [],
            },
            {
              name: '投标异常情况分析',
              route: '/czy/specialCaseTreatment/bidAbnormalAnalysis',
              icon: '',
              open: false,
            },
          ],
        },
        {
          name: '评价管理',
          route: null,
          icon: 'md-settings',
          open: false,
          child: [
            {
              name: '采购人评价',
              route: '/czy/evaluateManage/purchaserEvaluate',
              icon: '',
              open: false,
            },
            {
              name: '代理机构评价',
              route: '/czy/evaluateManage/agencyEvaluate',
              icon: '',
              open: false,
            },
            {
              name: '专家评价',
              route: '/czy/evaluateManage/expertEvaluate',
              icon: '',
              open: false,
            },
            {
              name: '供应商评价',
              route: '/czy/evaluateManage/providerEvaluate',
              icon: '',
              open: false,
            },
          ],
        },
        // {
        //   name: '工作台',
        //   route: null,
        //   icon: 'md-settings',
        //   open: false,
        //   child: [
        //     {
        //       name: '代理机构',
        //       route: '/czy/workbench/agency',
        //       icon: '',
        //       open: false,
        //     },
        //     {
        //       name: '采购人',
        //       route: '/czy/workbench/purchaser',
        //       icon: '',
        //       open: false,
        //     },
        //     {
        //       name: '供应商-可参加',
        //       route: '/czy/workbench/provider',
        //       icon: '',
        //       open: false,
        //     },
        //   ],
        // },
        /* 示例 end */
      ],
      // 代理机构目录
      dljgMenu: [
        {
          name: '委托代理合同',
          icon: 'md-settings',
          open: false,
          route: '/czy/entrustAgencyContract',
        },
        {
          name: '项目受理及分派',
          route: null,
          icon: 'md-settings',
          open: false,
          child: [
            {
              name: '需求受理列表',
              route: '/czy/projectAcceptDispatch/demandAcceptList',
              icon: null,
              open: false,
            },
            {
              name: '部门分派',
              route: '/czy/projectAcceptDispatch/departmentDispatch',
              icon: '',
              open: false,
            },
            {
              name: '经办人分派',
              route: '/czy/projectAcceptDispatch/agentDispatch',
              icon: '',
              open: false,
            },
          ],
        },
        {
          name: '采购项目管理',
          route: null,
          icon: 'md-settings',
          open: false,
          child: [
            {
              name: '开标/评标',
              route: '/czy/purchaseProjectManage/bidOpening',
              icon: '',
              open: false,
            },
            {
              name: '项目生成',
              route: '/czy/purchaseProjectManage/projectGeneration',
              icon: '',
              open: false,
            },
            {
              name: '需求公示',
              route: '/czy/purchaseProjectManage/demandFormula',
              icon: '',
              open: false,
            },
            {
              name: '需求公示审核',
              route: '/czy/purchaseProjectManage/demandFormulaAudit',
              icon: '',
              open: false,
            },
            {
              name: '采购文件制作',
              route: '/czy/purchaseProjectManage/procurementDocumentMake',
              icon: null,
              open: false,
            },

            {
              name: '采购文件批注',
              route: '/czy/purchaseProjectManage/documentAnnotation',
              icon: null,
              open: false,
            },
            {
              name: '采购文件下载',
              route: '/czy/purchaseProjectManage/fileDownload',
              icon: null,
              open: false,
            },

            {
              name: '采购文件上传',
              route: '/czy/purchaseProjectManage/fileUpload',
              icon: null,
              open: false,
            },
            {
              name: '内审意见统计',
              route: '/czy/purchaseProjectManage/opinionStatistics',
              icon: null,
              open: false,
            },
            {
              name: '采购文件需求沟通',
              route: '/czy/entrustPurchase/purchaseFileDemandChat',
              icon: '',
              open: false,
            },
            {
              name: '采购文件审核',
              route: '/czy/purchaseProjectManage/fileAudit',
              icon: null,
              open: false,
            },
            {
              name: '采购公告发布',
              route: '/czy/purchaseProjectManage/announceRelease',
              icon: null,
              open: false,
            },

            {
              name: '项目登记及审核',
              route: '/czy/purchaseProjectManage/registrationAudit',
              icon: null,
              open: false,
            },
            {
              name: '采购报告制作',
              route: '/czy/purchaseProjectManage/PurchaseReportMake',
              icon: null,
              open: false,
            },
            {
              name: '采购报告审核',
              route: '/czy/purchaseProjectManage/PurchaseReportAudit',
              icon: null,
              open: false,
            },
            {
              name: '采购报告退回审核',
              route: '/czy/purchaseProjectManage/PurchaseReportBackAudit',
              icon: null,
              open: false,
            },
            {
              name: '结果公告发布',
              route: '/czy/purchaseProjectManage/resultAnnouncePublish',
              icon: null,
              open: false,
            },
            {
              name: '中标通知书管理',
              route: '/czy/purchaseProjectManage/bidNotificationManage',
              icon: null,
              open: false,
            },
            {
              name: '中标通知书审核',
              route: '/czy/purchaseProjectManage/bidNotificationAudit',
              icon: null,
              open: false,
            },

            {
              name: '采购中心审核列表',
              route: '/czy/purchaseProjectManage/purchaseAuditList',
              icon: null,
              open: false,
            },
            {
              name: '专家申请',
              route: '/czy/purchaseProjectManage/expertApplication',
              icon: null,
              open: false,
            },
            {
              name: '专家申请确定',
              route: '/czy/purchaseProjectManage/expertApplicationConfirm',
              icon: null,
              open: false,
            },
          ],
        },
        {
          name: '代理评价管理',
          icon: 'md-settings',
          open: false,
          child: [
            {
              name: '代理-采购人评价',
              route: '/czy/agencyEvalManage/purchaser',
              icon: '',
              open: false,
            },
            {
              name: '代理-专家评价',
              route: '/czy/agencyEvalManage/expert',
              icon: '',
              open: false,
            },
            {
              name: '代理-供应商评价',
              route: '/czy/agencyEvalManage/provider',
              icon: '',
              open: false,
            },
          ],
        },
        {
          name: '代理机构框架协议签订',
          route: '/czy/dljgFrameAgreementSign',
          icon: 'md-settings',
          open: false,
        },
        {
          name: '模板管理',
          route: null,
          icon: 'md-settings',
          open: false,
          child: [
            {
              name: '开标一览表列表',
              route: '/czy/templateManagement/listOfBidOpening',
              icon: null,
              open: false,
            },
            {
              name: '评标方法模板',
              route: '/czy/templateManagement/bidEvaluation',
              icon: null,
              open: false,
            },
            {
              name: '资料审查模板',
              route: '/czy/templateManagement/dataReview',
              icon: null,
              open: false,
            },
            {
              name: '资格审查模板',
              route: '/czy/templateManagement/qualificationExamination',
              icon: null,
              open: false,
            },
            {
              name: '符合审查模板',
              route: '/czy/templateManagement/complianceReview',
              icon: null,
              open: false,
            },
            {
              name: '评分公式模板',
              route: '/czy/templateManagement/scoringFormula',
              icon: null,
              open: false,
            },
            {
              name: '响应文件模板',
              route: '/czy/templateManagement/responseFile',
              icon: null,
              open: false,
            },
            {
              name: '公告模板',
              route: '/czy/templateManagement/announcement',
              icon: null,
              open: false,
            },
            {
              name: '委托协议模板',
              route: '/czy/templateManagement/commissionAgreement',
              icon: null,
              open: false,
            },
            {
              name: '通知书模板',
              route: '/czy/templateManagement/notice',
              icon: null,
              open: false,
            },
            {
              name: '框架协议淘汰原因模板',
              route: '/czy/templateManagement/reason',
              icon: null,
              open: false,
            },
          ],
        },
        {
          name: '审批管理平台',
          route: null,
          icon: 'md-settings',
          open: false,
          child: [
            {
              name: '交易系统审核',
              route: '/czy/examineManage/transactionReview',
              icon: null,
              open: false,
            },
          ],
        },
        {
          name: '系统设置',
          route: null,
          icon: 'md-settings',
          open: false,
          child: [
            {
              name: '个人信息',
              route: '/czy/system/personalInfo',
              icon: null,
              open: false,
              // }, {
              // 	name: '账户管理',
              // 	route: '/czy/system/account',
              // 	icon: null,
              // 	open: false,
              // }, {
              // 	name: '角色管理',
              // 	route: '/czy/system/role',
              // 	icon: null,
              // 	open: false,
              // }, {
              // 	name: '短信日志',
              // 	route: '/czy/system/messageLog',
              // 	icon: '',
              // 	open: false
            },
          ],
        },
        {
          name: '日志中心',
          route: null,
          icon: 'md-settings',
          open: false,
          child: [
            {
              name: '登录日志',
              route: '/czy/logCenter/loginLog',
              icon: null,
              open: false,
            },
            {
              name: '文件日志',
              route: '/czy/logCenter/fileLog',
              icon: '',
              open: false,
            },
          ],
        },
        // {
        //   name: '工作台',
        //   route: '/czy/workbench/agency',
        //   icon: '',
        //   open: false,
        // },

        {
          name: '澄清与质疑管理',
          route: null,
          icon: 'md-settings',
          open: false,
          child: [
            {
              name: '采购文件质疑回复',
              route: '/czy/clarifyAndQuestion/fileChallengeAccept',
              icon: null,
              open: false,
              child: [],
            },
            {
              name: '采购过程质疑回复',
              route: '/czy/clarifyAndQuestion/processChallengeAccept',
              icon: null,
              open: false,
              child: [],
            },
            {
              name: '采购结果质疑回复',
              route: '/czy/clarifyAndQuestion/resultChallengeAccept',
              icon: null,
              open: false,
              child: [],
            },
            {
              name: '质疑审核',
              route: '/czy/clarifyAndQuestion/auditAccept',
              icon: null,
              open: false,
              child: [],
            },
            {
              name: '招标问题回复',
              route: '/czy/clarifyAndQuestion/replyToTenderQuestion',
              icon: null,
              open: false,
              child: [],
            },
          ],
        },
        {
          name: '电子归档',
          route: '/czy/electronicFiling/index',
          icon: null,
          open: false,
        },
        {
          name: '项目统计及查询',
          route: null,
          icon: 'md-settings',
          open: false,
          child: [
            {
              name: '项目查询',
              route: '/czy/projectStatisticsInquiry/itemQuery',
              icon: '',
              open: false,
            },
            {
              name: '数据上报模型',
              route: '/czy/projectStatisticsInquiry/dataReportingModel',
              icon: '',
              open: false,
            },
            {
              name: '财政项目统计',
              route: '/czy/projectStatisticsInquiry/financialStatistics',
              icon: '',
              open: false,
            },
            {
              name: '项目检索',
              route: '/czy/projectStatisticsInquiry/projectRetrieval',
              icon: '',
              open: false,
            },
            {
              name: '计划执行情况统计',
              route:
                '/czy/projectStatisticsInquiry/planImplementationStatistics',
              icon: '',
              open: false,
            },
            {
              name: '供应商分析',
              route: '/czy/projectStatisticsInquiry/supplierAnalysis',
              icon: '',
              open: false,
            },
            {
              name: '专家参与分析',
              route:
                '/czy/projectStatisticsInquiry/expertParticipationAnalysis',
              icon: '',
              open: false,
            },
          ],
        },
        {
          name: '特殊情况处理',
          route: null,
          icon: 'md-settings',
          open: false,
          child: [
            {
              name: '更正公告制作',
              route: '/czy/specialCaseTreatment/correctionAnnounceMake',
              icon: null,
              open: false,
              child: [],
            },
            {
              name: '更正公告审核',
              route: '/czy/specialCaseTreatment/correctionAnnounceAudit',
              icon: null,
              open: false,
              child: [],
            },
            {
              name: '废标公告制作',
              route: '/czy/specialCaseTreatment/bidNoticeMake',
              icon: '',
              open: false,
            },
            {
              name: '废标公告审核',
              route: '/czy/specialCaseTreatment/bidNoticeReview',
              icon: '',
              open: false,
            },
            {
              name: '重评申请',
              route: '/czy/specialCaseTreatment/reappraisalApplication',
              icon: null,
              open: false,
              child: [],
            },
            {
              name: '重评审核',
              route: '/czy/specialCaseTreatment/reappraisalAudit',
              icon: null,
              open: false,
              child: [],
            },
            {
              name: '需求公示延期',
              route: '/czy/specialCaseTreatment/demandPostponement',
              icon: '',
              open: false,
            },
            {
              name: '需求公示延期审核',
              route: '/czy/specialCaseTreatment/demandPostponementReview',
              icon: '',
              open: false,
            },
            {
              name: '需求公示废包公告',
              route: '/czy/specialCaseTreatment/demandAbolish',
              icon: '',
              open: false,
            },
            {
              name: '需求公示废包审核',
              route: '/czy/specialCaseTreatment/demandAbolishReview',
              icon: '',
              open: false,
            },
          ],
        },
      ],
      // 采购人目录
      cgrMenu: [
        {
          name: '委托采购',
          route: null,
          icon: 'md-settings',
          open: false,
          child: [
            {
              name: '计划录入',
              route: '/czy/entrustPurchase/planEntry',
              icon: null,
              open: false,
            },
            {
              name: '委托协议签订',
              route: '/czy/entrustPurchase/signAgreement',
              icon: '',
              open: false,
            },
            {
              name: '需求委托',
              route: '/czy/entrustPurchase/demandEntrust',
              icon: '',
              open: false,
            },
            {
              name: '采购文件确认',
              route: '/czy/entrustPurchase/purchaseDocumentConfirm',
              icon: '',
              open: false,
            },
            {
              name: '采购结果确认',
              route: '/czy/entrustPurchase/purchaseResultConfirm',
              icon: '',
              open: false,
            },
            {
              name: '采购结果查看',
              route: '/czy/entrustPurchase/purchaseResultCheck',
              icon: '',
              open: false,
            },
            // {
            // 	name: '采购文件需求沟通',
            // 	route: '/czy/entrustPurchase/purchaseFileDemandChat',
            // 	icon: '',
            // 	open: false
            // },
            {
              name: '中标通知书确认',
              route: '/czy/entrustPurchase/bidNotificationConfirm',
              icon: '',
              open: false,
            },
            {
              name: '评委授权函/说明函录入',
              route: '/czy/entrustPurchase/letterEntry',
              icon: '',
              open: false,
            },
            {
              name: '资格性审查',
              route: '/czy/entrustPurchase/qualificationExamination',
              icon: '',
              open: false,
            },
          ],
        },
        {
          name: '采购人评价管理',
          icon: 'md-settings',
          open: false,
          child: [
            {
              name: '采购人-代理机构评价',
              route: '/czy/PurchaserEvalManage/agency',
              icon: '',
              open: false,
            },
            {
              name: '采购人-专家评价',
              route: '/czy/PurchaserEvalManage/expert',
              icon: '',
              open: false,
            },
            {
              name: '采购人-供应商评价',
              route: '/czy/PurchaserEvalManage/provider',
              icon: '',
              open: false,
            },
          ],
        },
        {
          name: '合同管理',
          route: null,
          icon: 'md-settings',
          open: false,
          child: [
            {
              name: '合同签订',
              route: '/czy/contractManagement/contractSigning',
              icon: null,
              open: false,
              child: [],
            },
            {
              name: '合同公示',
              route: '/czy/contractManagement/contractPublicity',
              icon: null,
              open: false,
              child: [],
            },
            {
              name: '供应商合同签订',
              route: '/czy/contractManagement/contractSigningSupplier',
              icon: null,
              open: false,
              child: [],
            },
          ],
        },
        {
          name: '基础配置',
          route: null,
          icon: 'md-settings',
          open: false,
          child: [
            {
              name: '电子证书设置',
              route: '/czy/basicConfig/eCertSet',
              icon: null,
              open: false,
            },
            {
              name: '计量单位录入',
              route: '/czy/basicConfig/unitEntry',
              icon: null,
              open: false,
            },
            {
              name: '采购类别',
              route: '/czy/basicConfig/purchasingClass',
              icon: null,
              open: false,
            },
            {
              name: '供应商资格库维护',
              route: '/czy/basicConfig/qualificationBank',
              icon: null,
              open: false,
            },
            {
              name: '敏感词管理',
              route: '/czy/basicConfig/sensitiveWord',
              icon: null,
              open: false,
            },
          ],
        },

        {
          name: '差异化配置',
          route: null,
          icon: 'md-settings',
          open: false,
          child: [
            {
              name: '差异功能设置',
              route: '/czy/differentiatedAllocation/differentialFunctionSet',
              icon: null,
              open: false,
            },
            {
              name: '差异流程设置',
              route: '/czy/differentiatedAllocation/differentialFlowSet',
              icon: null,
              open: false,
            },
          ],
        },
        {
          name: '采购人框架协议签订',
          route: '/czy/cgrFrameAgreementSign',
          icon: 'md-settings',
          open: false,
        },
        {
          name: '入围信息公告',
          route: '/czy/finalistAnnouncement',
          icon: 'md-settings',
          open: false,
        },
        {
          name: '中标通知书确认',
          route: '/czy/entrustPurchase/bidNotificationConfirm',
          icon: '',
          open: false,
        },
        {
          name: '评委授权函/说明函录入',
          route: '/czy/entrustPurchase/letterEntry',
          icon: '',
          open: false,
        },
        // {
        //   name: '工作台',
        //   route: '/czy/workbench/purchaser',
        //   icon: '',
        //   open: false,
        // },
      ],
      // 专家目录
      zjMenu: [
        {
          name: '评标',
          route: '/czy/purchaseProjectManage/bidEvaluation',
          icon: '',
          open: false,
        },
        {
          name: '征集-采购文件确定',
          route: '/czy/collectPurchaseFiles',
          icon: 'md-settings',
          open: false,
        },
        {
          name: '征集-供应商资料审核',
          route: '/czy/supplierDataAudit',
          icon: 'md-settings',
          open: false,
        },
        {
          name: '重评',
          route: '/czy/purchaseProjectManage/reevaluate',
          icon: '',
          open: false,
        },
        {
          name: '专家-评价管理',
          icon: 'md-settings',
          open: false,
          child: [
            {
              name: '专家-采购人评价',
              route: '/czy/expertEvalManage/purchaser',
              icon: '',
              open: false,
            },
            {
              name: '专家-代理机构评价',
              route: '/czy/expertEvalManage/agency',
              icon: '',
              open: false,
            },
          ],
        },
      ],
      // 供应商目录
      gysMenu: [
        {
          name: '最终报价',
          route: '/czy/participateBid/finalOffer',
          icon: '',
          open: false,
        },
        {
          name: '询标记录',
          route: '/czy/bidRecord',
          icon: 'md-settings',
          open: false,
        },
        {
          name: '澄清回复',
          route: '/czy/bidClear',
          icon: 'md-settings',
          open: false,
        },
        {
          name: '实质性条款变动',
          route: '/czy/materialTermsChange',
          icon: 'md-settings',
          open: false,
        },
        {
          name: '供应商评价管理',
          icon: 'md-settings',
          open: false,
          child: [
            {
              name: '供应商-采购人评价',
              route: '/czy/providerEvalManage/purchaser',
              icon: '',
              open: false,
            },
            {
              name: '供应商-代理机构评价',
              route: '/czy/providerEvalManage/agency',
              icon: '',
              open: false,
            },
          ],
        },
        {
          name: '供应商合同管理',
          route: '/czy/contractManagement/contractSigningSupplier',
          icon: null,
          open: false,
        },
        {
          name: '供应商框架协议签订',
          route: '/czy/gysFrameAgreementSign',
          icon: 'md-settings',
          open: false,
        },
        {
          name: '参与投标',
          route: null,
          icon: 'md-settings',
          open: false,
          child: [
            {
              name: '下载标书',
              route: '/czy/participateBid/downloadTender',
              icon: null,
              open: false,
            },
            {
              name: '项目登记',
              route: '/czy/participateBid/projectRegistration',
              icon: '',
              open: false,
            },
            {
              name: '在线投标',
              route: '/czy/participateBid/bidOnline',
              icon: '',
              open: false,
            },
            {
              name: '中标通知书下载',
              route: '/czy/participateBid/bidNoticeDownload',
              icon: '',
              open: false,
            },
            {
              name: '未中标通知书下载',
              route: '/czy/participateBid/unBidNoticeDownload',
              icon: '',
              open: false,
            },
          ],
        },
        {
          name: '质疑管理',
          route: '/czy/clarifyAndQuestion/questioningManagement',
          icon: null,
          open: false,
          child: [],
        },
        // {
        //   name: '工作台',
        //   route: '/czy/workbench/provider',
        //   icon: '',
        //   open: false,
        // },
      ],
      // 监管目录
      jgMenu: [
        {
          name: '评价管理',
          route: null,
          icon: 'md-settings',
          open: false,
          child: [
            {
              name: '采购人评价',
              route: '/czy/evaluateManage/purchaserEvaluate',
              icon: '',
              open: false,
            },
            {
              name: '代理机构评价',
              route: '/czy/evaluateManage/agencyEvaluate',
              icon: '',
              open: false,
            },
            {
              name: '专家评价',
              route: '/czy/evaluateManage/expertEvaluate',
              icon: '',
              open: false,
            },
            {
              name: '供应商评价',
              route: '/czy/evaluateManage/providerEvaluate',
              icon: '',
              open: false,
            },
          ],
        },
        {
          name: '特殊情况处理',
          route: null,
          icon: 'md-settings',
          open: false,
          child: [
            {
              name: '投标文件异常',
              route: '/czy/specialCaseTreatment/bidFileAbnormal',
              icon: '',
              open: false,
            }
          ],
        },
      ],
      // 运营后台目录
      yyhtMenu: [
        {
          name: '区划管理',
          route: null,
          icon: 'md-settings',
          open: false,
          child: [
            {
              name: '区划设置',
              route: '/czy/regionalAllocation/regionalization',
              icon: null,
              open: false,
              child: [],
            },
            {
              name: '区划管理员',
              route: '/czy/regionalAllocation/districtManager',
              icon: null,
              open: false,
            },
          ],
        },

        {
          name: '基础配置',
          route: null,
          icon: 'md-settings',
          open: false,
          child: [
            {
              name: '电子证书设置',
              route: '/czy/basicConfig/eCertSet',
              icon: null,
              open: false,
            },
            {
              name: '计量单位录入',
              route: '/czy/basicConfig/unitEntry',
              icon: null,
              open: false,
            },
            {
              name: '采购类别',
              route: '/czy/basicConfig/purchasingClass',
              icon: null,
              open: false,
            },
            {
              name: '供应商资格库维护',
              route: '/czy/basicConfig/qualificationBank',
              icon: null,
              open: false,
            },
            {
              name: '敏感词管理',
              route: '/czy/basicConfig/sensitiveWord',
              icon: null,
              open: false,
            },
          ],
        },
        {
          name: '差异化配置',
          route: null,
          icon: 'md-settings',
          open: false,
          child: [
            {
              name: '差异功能设置',
              route: '/czy/differentiatedAllocation/differentialFunctionSet',
              icon: null,
              open: false,
            },
            {
              name: '差异流程设置',
              route: '/czy/differentiatedAllocation/differentialFlowSet',
              icon: null,
              open: false,
            },
          ],
        },

        {
          name: '模板管理',
          route: null,
          icon: 'md-settings',
          open: false,
          child: [
            {
              name: '开标一览表列表',
              route: '/czy/templateManagement/listOfBidOpening',
              icon: null,
              open: false,
            },
            {
              name: '评标方法模板',
              route: '/czy/templateManagement/bidEvaluation',
              icon: null,
              open: false,
            },
            {
              name: '资料审查模板',
              route: '/czy/templateManagement/dataReview',
              icon: null,
              open: false,
            },
            {
              name: '资格审查模板',
              route: '/czy/templateManagement/qualificationExamination',
              icon: null,
              open: false,
            },
            {
              name: '符合审查模板',
              route: '/czy/templateManagement/complianceReview',
              icon: null,
              open: false,
            },
            {
              name: '评分公式模板',
              route: '/czy/templateManagement/scoringFormula',
              icon: null,
              open: false,
            },
            {
              name: '响应文件模板',
              route: '/czy/templateManagement/responseFile',
              icon: null,
              open: false,
            },
            {
              name: '公告模板',
              route: '/czy/templateManagement/announcement',
              icon: null,
              open: false,
            },
            {
              name: '委托协议模板',
              route: '/czy/templateManagement/commissionAgreement',
              icon: null,
              open: false,
            },
            {
              name: '通知书模板',
              route: '/czy/templateManagement/notice',
              icon: null,
              open: false,
            },
            {
              name: '框架协议淘汰原因模板',
              route: '/czy/templateManagement/reason',
              icon: null,
              open: false,
            },
          ],
        },
        {
          name: '审批管理平台',
          route: null,
          icon: 'md-settings',
          open: false,
          child: [
            {
              name: '交易系统审核',
              route: '/czy/examineManage/transactionReview',
              icon: null,
              open: false,
            },
          ],
        },
        {
          name: '系统设置',
          route: null,
          icon: 'md-settings',
          open: false,
          child: [
            {
              name: '个人信息',
              route: '/czy/system/personalInfo',
              icon: null,
              open: false,
              // }, {
              // 	name: '账户管理',
              // 	route: '/czy/system/account',
              // 	icon: null,
              // 	open: false,
              // }, {
              // 	name: '角色管理',
              // 	route: '/czy/system/role',
              // 	icon: null,
              // 	open: false,
              // }, {
              // 	name: '短信日志',
              // 	route: '/czy/system/messageLog',
              // 	icon: '',
              // 	open: false
            },
          ],
        },
        {
          name: '日志中心',
          route: null,
          icon: 'md-settings',
          open: false,
          child: [
            {
              name: '登录日志',
              route: '/czy/logCenter/loginLog',
              icon: null,
              open: false,
            },
            {
              name: '文件日志',
              route: '/czy/logCenter/fileLog',
              icon: '',
              open: false,
            },
          ],
        },
      ],
      routeList: [],
      buttonAuthList: [],
    },
    leftMenuNeedRefresh: false, // 左侧菜单是否需要刷新 解决点击菜单无法打开对应菜单首页问题
    menuList: null,
    keyCollapse: false
  },
  mutations: {
    $setState(state, data) {
      let target = state
      let keyList = data.key.split('.'),
        lastKey = keyList.pop()
      for (let key of keyList) {
        target = target[key]
      }
      Vue.set(target, lastKey, data.value)
    },
  },
  actions: {},
  modules: {},
  getters: {
    menuList: (state) => {
      return state.menuList || localStorage.getItem('menuList')
    },
  },
})
