
<template>
    <div>
        <maskLayer />
        <Row class="loading-svg">
            <Col class="demo-spin-col" span="8">
            <Spin fix>
                <Icon type="ios-loading" size=40 class="demo-spin-icon-load"></Icon>
                <div :style="{fontSize: text === 'Loading' ? '20px' : '14px', minWidth: '800px'}">{{ text }}</div>
            </Spin>
            </Col>
        </Row>
    </div>
</template>

<script>
export default {
    props: {
        loadingText: {
            type: String,
            default: '',
        },
    },
    components: {},
    data() {
        return {
            text: '',
        }
    },
    computed: {},
    watch: {
        loadingText: {
            immediate: true,
            handler() {
                if (this.loadingText === '') {
                    this.text = 'Loading'
                } else {
                    this.text = this.loadingText
                }
            },
        },
    },
    methods: {},
    mounted() {},
}
</script>

<style scoped>
.demo-spin-icon-load {
    animation: ani-demo-spin 1s linear infinite;
}
@keyframes ani-demo-spin {
    from {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(180deg);
    }
    to {
        transform: rotate(360deg);
    }
}
.demo-spin-col {
    height: 100px;
    position: relative;
}
.global-loading {
    display: flex;
    justify-content: center;
    align-items: center;
}
.loading-svg {
    z-index: 9999999999;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -36px;
    margin-top: -50px;
}
</style>
