<template>
	<div class="header clear-float" v-if="!keyCollapse">
		<a class="logo">
			<img class="img" src="./image/header.png" />
			<span class="line">|</span>
			<span class="text">{{ userInfo.enterpriseName }}</span>
		</a>
		<div class="user">
			<el-dropdown class="dropdownUp" v-if="userInfo.operatorType == 0 || userInfo.operatorType == 5 || userInfo.operatorType == 3">
				<span class="el-dropdown-link"><i class="el-icon-question"></i>帮助中心 &nbsp;</span>
				<el-dropdown-menu slot="dropdown">
					<el-dropdown-item v-if="userInfo.operatorType == 0 || userInfo.operatorType == 5" @click.native="donwang(1)">代理机构操作手册</el-dropdown-item>

					<el-dropdown-item v-if="userInfo.operatorType == 0 || userInfo.operatorType == 5" @click.native="donwang(2)">CA签章助手</el-dropdown-item>
					<el-dropdown-item v-if="userInfo.operatorType == 0 || userInfo.operatorType == 5" @click.native="donwang(3)">供应商客户端</el-dropdown-item>
					<el-dropdown-item v-if="userInfo.operatorType == 0 || userInfo.operatorType == 5" @click.native="donwang(4)">供应商操作手册</el-dropdown-item>
					<el-dropdown-item v-if="userInfo.operatorType == 0 || userInfo.operatorType == 5 || userInfo.operatorType == 3" @click.native="donwang(5)">专家操作手册</el-dropdown-item>
				</el-dropdown-menu>
			</el-dropdown>
			<el-button class="color-white entity-type" type="text" @click="handleToWorkbench" v-if="userInfo.operatorType != 4 && userInfo.operatorType != 5">
				<i class="el-icon-monitor mr5" size="20"></i>工作台</el-button
			>
			<p class="entity-type">
				<span class="system-entity">{{ operatorTypeOpts[userInfo.operatorType] }} | {{ userInfo.name }}</span>
			</p>
			<el-button class="color-white entity-type" type="text" @click="handleResetPassword" v-if="userInfo.operatorType != 4 && userInfo.operatorType != 5">
				<i class="el-icon-s-tools mr5" size="20"></i>修改密码
			</el-button>
			<el-button class="color-white entity-type" style="margin-left: 0" type="text" @click="userExit"> <i class="el-icon-back mr5" size="20"></i>退出 </el-button>
		</div>
		<resetPassword ref="resetPassword"></resetPassword>
	</div>
</template>

<script>
import { down } from '@/plugin/util'
import { filedownload, getossDataurl } from '@/axios/api/main.js'
import { mapState } from 'vuex'
import { removeToken } from '@/axios/api/topHeader.js'
import resetPassword from '@/views/main/login/vue/resetPassword.vue'
export default {
	components: { resetPassword },
	data() {
		return {
			poptipVisible: false,
			operatorTypeOpts: ['普通用户', '超级管理员', '区划管理员', '专家', '供应商', '代理机构管理', '采购人', '监管机构'],
			userInfo: JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo')) : {}
		}
	},
	computed: {
		...mapState(['systemEntity', 'options']),
		keyCollapse() {
			return this.$store.state.keyCollapse
		}
	},
	methods: {
		init() {
			this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
		},
		handleResetPassword() {
			//重置密码
			this.$refs.resetPassword.showDialog('修改密码')
		},
		handleToWorkbench() {
			if (this.$route.path.includes('/workbench')) {
				this.$router.go(0)
				return
			}
			if (this.userInfo.operatorType == '4') {
				this.$router.push({ path: '/workbench/provider' })
			} else if (this.userInfo.operatorType == '0' || this.userInfo.operatorType == '5') {
				this.$router.push({ path: '/workbench/agency' })
			} else if (this.userInfo.operatorType == '6') {
				this.$router.push({ path: '/workbench/purchaser' })
			} else if (this.userInfo.operatorType == '7') {
				this.$router.push({ path: '/workbench/superviseCockpit' })
			} else {
				this.$router.push({ path: '/workbench/agency' })
			}
		},
		userExit() {
			// 退出
			localStorage.removeItem('token')
			removeToken()
				.then((res) => {
					this.$b.removeStorage()
					if (localStorage.getItem('loginType') && localStorage.getItem('areaCode')) {
						this.$router.push({ path: '/login', query: { loginType: localStorage.getItem('loginType'), zoneId: localStorage.getItem('areaCode') } })
					} else if (localStorage.getItem('loginType')) {
						this.$router.push({ path: '/login', query: { loginType: localStorage.getItem('loginType') } })
					} else if (localStorage.getItem('areaCode')) {
						this.$router.push({ path: '/login', query: { zoneId: localStorage.getItem('areaCode') } })
					} else {
						this.$router.push({ path: '/login' })
					}
				})
				.catch((err) => {
					return
				})
		},
		donwang(e) {
			console.log(e)
			let DLJGossId = '981810955439005696' //代理机构操作手册
			let GYSoossId = '981811129943023617' //供应商操作手册
			let ZJoossId = '982788278573293569' //专家操作手册
			let KHDoossId = '981818662153838593' //客户端
			let CAoossId = '982917276838092801' //CA签章助手
			let urlCa =
				'http://183.95.190.75:8088/api/file/api/fileDownload?param1=1754a6f2-aadc-92cb-0106-4dd7b2b108b7&param2=30&param3=signId&param4=T-1&param5=30450220641ad97559a6acbd71535dde60b380ae9f894e834fdfc519366170188c70b04e022100ccbba3b9cb3bfcfa0988452ce6aedbd5c0903a82581aab73b9d0f5ac34286366'
			if (e == 1) {
				if (this.userInfo.operatorType == 0 || this.userInfo.operatorType == 5) {
					this.fliedonw(DLJGossId)
				} else {
					this.fliedonw(ZJoossId)
				}
			} else if (e == 2) {
				this.fliedonw(CAoossId)
				// window.open(urlCa, ' _blank')
			} else if (e == 3) {
				this.fliedonw(KHDoossId)
			} else if (e == 4) {
				this.fliedonw(GYSoossId)
			} else if (e == 5) {
				this.fliedonw(ZJoossId)
			}
			// 0/5 代理机构
			// 4 供应商
		},
		fliedonw(Id) {
			getossDataurl({ ossId: Id })
				.then((res) => {
					console.log(res)
					// down(res)
					// window.open(res.url, '_blank')
					// 新窗口打开后，设置标题的代码
					var newWindow = window.open(res.url, '_blank')
					if (newWindow) {
						newWindow.onload = function () {
							newWindow.document.title = res.fileName
						}
					}
				})
				.catch((err) => {})
		}
	},
	created() {},
	mounted() {}
}
</script>

<style scoped>
a,
a:hover {
	color: #fff;
}

.header {
	position: relative;
	padding: 0 20px;
	height: 56px;
	line-height: 56px;
	background-color: #1f293d;
	/* background-color: #0266be; */
}

.header > .logo > .line {
	padding: 0 12px;
	color: #ffffff;
}

.header > .logo > .text {
	color: #ffffff;
	font-size: 16px;
	font-weight: 500;
}

.header > .logo > .img {
	margin-top: 15px;
	vertical-align: top;
}

.header > .user {
	float: right;
	cursor: pointer;
	color: #ffffff;
}

.header > .user .entity-type {
	margin-right: 20px;
	display: inline-block;
}

.header > .user .entity-type .system-entity {
	margin-right: 5px;
	font-size: 12px;
}

.color-white {
	color: white;
}
.dropdownUp {
	margin-right: 20px;
	color: #fff;
	font-size: 12px;
}
.el-dropdown-menu {
	background-color: #1f293d;
	color: #fff;
	top: 40px;
	border: none;
	font-size: 12px;
}
.el-dropdown-menu li {
	color: #fff;
	font-size: 12px;
}
.el-dropdown-menu .el-popper .popper__arrow::after {
	top: 0px;
	margin-left: -6px;
	border-top-width: 0;
	border-bottom-color: #1f293d !important;
}
</style>
