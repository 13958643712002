import store from '@/store/index.js'
import smCrypto from 'sm-crypto'
import { validatenull } from '../plugin/util'

import {
	resourceList,
	accountCheckUniqueAccount,
	getSystemBaseCode,
	listTenantEntityTypeByTenantCode,
	checkNameOrCreditCodeExist,
	systemTree
} from '@/axios/api/base.js'
// import { tlList } from '@/axios/api/entity.js'
import {
	roleList
} from '@/axios/api/system.js'

let routeList = []
let buttonAuthList = []

function getMenuChild(menuTree) {
	let menuList = []
	for (let menu of menuTree) {
		if (menu.hidden === '1') {
			if (menu.type === '3' && menu.route) {
				routeList.push(menu.route)
			}
			if (menu.type === '4') {
				buttonAuthList.push(menu.route)
			}

			let obj = {
				name: menu.name,
				route: menu.route,
				icon: menu.icon,
				open: false,
			}
			if (menu.children.length) {
				obj.child = getMenuChild(menu.children)
			}
			if (['1', '2', '3'].includes(menu.type)) {
				menuList.push(obj)
			}
		}
	}
	return menuList
}

let base = {
	sm2DoEncrypt(string, publicKey) {
		// 国密 sm2 加密
		let msg = string;
		if (typeof (string) !== 'string') {
			msg = JSON.stringify(string);
		}

		let sm2 = smCrypto.sm2;

		// let keypair = sm2.generateKeyPairHex()
		// let publicKey = keypair.publicKey // 公钥-前端生成的

		let cipherMode = 1; // 加密方式: 1-C1C3C2 0-C1C2C3 默认为1 使用0 则在加密后的密文前面需要加04
		// let publicKey = 'MFkwEwYHKoZIzj0CAQYIKoEcz1UBgi0DQgAEnpvlJgOD+z38ESt+LpIh2n48QpB13RI+W/Ib6dBd1VGkE2K8N2MWGBjycsor0DEGQ+k2EZIbxqzW3RPeZSzUgg=='; // 公钥-由后台提供 注意可能要在原公钥前加04
		// let publicKey =
		// 	'04MFkwEwYHKoZIzj0CAQYIKoEcz1UBgi0DQgAEnpvlJgOD+z38ESt+LpIh2n48QpB13RI+W/Ib6dBd1VGkE2K8N2MWGBjycsor0DEGQ+k2EZIbxqzW3RPeZSzUgg=='; // 公钥-由后台提供 注意可能要在原公钥前加04
		// console.log(publicKey)
		let encryptData = sm2.doEncrypt(msg, publicKey, cipherMode); // 加密结果
		// return encryptData;
		return '04' + encryptData;
	},
	async getResourceTree(isAll) {
		let list = []
		let data = {
			operationType: 'list',
			systemRootCode: isAll ? '0' : store.state.userInfo.systemCode,
			entityType: store.state.userInfo.entityType,
			tenantCode: store.state.userInfo.tenantCode,
			userType: store.state.userInfo.userType
		}
		await resourceList(data)
			.then(res => {
				// console.log(res)
				if (res && res.length) {
					list = res
				}
			})
			.catch(err => {
				console.log(err)
				return
			})
		return list
	},
	async createMenuList() {
		// 获取当前用户资源（权限）信息
		let curList = await base.getResourceTree(false)
		let menu = {
			menuList: getMenuChild(curList),
			routeList,
			buttonAuthList,
		}
		store.commit('$setState', {
			key: 'menu',
			value: menu
		})
		localStorage.setItem('menu', JSON.stringify(menu))

		// console.log('menu========>')
		// console.log(menu)
	},
	async setTreeList() {
		// 获取完整资源（权限）信息
		let list = await base.getResourceTree(true)
		let resource = {
			treeList: list.map((i) => lodash.assignInWith(i, {
				_showChildren: true
			})),
		}
		store.commit('$setState', {
			key: 'resource',
			value: resource
		})
		localStorage.setItem('resource', JSON.stringify(resource))

		// console.log('resource========>')
		// console.log(resource)
	},
	async checkUniqueAccount(userName) {
		let info = ''
		let params = {
			userName
		}
		await accountCheckUniqueAccount(params)
			.then(res => {
				// console.log(res)
				// 这里在返回null 说明校验无问题 若检验存在问题 接口会有返回信息
				if (res) {
					info = res.msg || '无报错信息'
				}
			})
			.catch(err => {
				console.log(err)
				return
			})
		return info
	},
	// checkNameOrCreditCodeExist
	async toCheckNameOrCreditCodeExist(data) {
		let isExist = false
		await checkNameOrCreditCodeExist(data)
			.then(res => {
				console.log(res)
				// 这里在返回null 说明校验无问题 若检验存在问题 接口会有返回信息
				isExist = res
			})
			.catch(err => {
				console.log(err)
				return
			})
		return isExist
	},
	async getAllRole() {
		let list = []
		let data = {
			operationType: 'list',
			pageSize: 10000000
		}
		await roleList(data)
			.then(res => {
				// console.log(res)
				if (res && res.list && res.list.length) {
					list = res.list.map((i) => {
						return {
							value: i.code,
							label: i.name,
						}
					})
				}
			})
			.catch(err => {
				console.log(err)
				return
			})
		return list
	},
	async selectOptions(sex) {
		let list = []

		await getSystemBaseCode({}, sex)
			.then(res => {
				// console.log(res)
				if (res && res.attributeVOList && res.attributeVOList.length) {
					list = res.attributeVOList
				}
			})
			.catch(err => {
				console.log(err)
				return
			})
		return list
	},
	async getEntityTenantMap(tenantCode) {
		let map = null

		let params = {
			tenantCode
		}
		await listTenantEntityTypeByTenantCode(params)
			.then(res => {
				// console.log(res)
				if (res) {
					map = res
				}
			})
			.catch(err => {
				console.log(err)
				return
			})
		return map
	},
	// async getPlIdOptions() {
	// 	let list = []
	// 	let data = {
	// 		entityCode: store.state.userInfo.entityCode
	// 	}
	// 	await tlList(data)
	// 		.then(res => {
	// 			// console.log(res)
	// 			if (res && res.list && res.list.length) {
	// 				list = res.list.map((i) => {
	// 					return {
	// 						value: i.teamLeaderCode,
	// 						label: i.cname,
	// 						identityId: i.identityId,
	// 					}
	// 				})
	// 			}
	// 		})
	// 		.catch(err => {
	// 			console.log(err)
	// 			return
	// 		})
	// 	return list
	// },
	// 获取系统（行业分类/）树
	async getSystemTree(type) {
		$this.$loading.show()
		let list = []
		let params = {
			type: type
		}
		await systemTree(params)
			.then(res => {
				// console.log(res)
				list = res
			})
			.catch(err => {
				console.log(err)
				return
			})
		return list
	},
	judgePassword(psd) {// 6-16位密码须由数字、字母或符号至少两种组成，其中符号可包括~!@#$%^&*，字母可以为大写字母也可以为小写字母
		let reg = new RegExp("^(?!^(\\d+|[a-zA-Z]+|[~!@#$%^&*?]+)$)^[\\w~!@#$%^&*?]{6,16}$");
		if (!reg.test(psd)) {
			return false;
		} else {
			return true;
		}
	},
	judgePassword2(psd) {// 10-20位密码须由数字、字母或符号至少两种组成，其中符号可包括~!@#$%^&*，字母可以为大写字母也可以为小写字母
		let reg = new RegExp("^(?!^(\\d+|[a-zA-Z]+|[~!@#$%^&*?]+)$)^[\\w~!@#$%^&*?]{10,20}$");
		if (!reg.test(psd)) {
			return false;
		} else {
			return true;
		}
	},
	judgePassword3(psd) {// 密码是否包含大小写字母、数字、特殊字符的四种缺一不可，长度8到16
		// let reg = /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\\W_!@#$%^&*`~()-+=]+$)(?![a-z0-9]+$)(?![a-z\\W_!@#$%^&*`~()-+=]+$)(?![0-9\\W_!@#$%^&*`~()-+=]+$)(?![a-zA-Z0-9]+$)(?![a-zA-Z\\W_!@#$%^&*`~()-+=]+$)(?![a-z0-9\\W_!@#$%^&*`~()-+=]+$)(?![0-9A-Z\\W_!@#$%^&*`~()-+=]+$)[a-zA-Z0-9\\W_!@#$%^&*`~()-+=]{10,16}$/
		let reg = /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\\_!@#$%^&*`~()-+=]+$)(?![a-z0-9]+$)(?![a-z\\_!@#$%^&*`~()-+=]+$)(?![0-9\\_!@#$%^&*`~()-+=]+$)(?![a-zA-Z0-9]+$)(?![a-zA-Z\\_!@#$%^&*`~()-+=]+$)(?![a-z0-9\\_!@#$%^&*`~()-+=]+$)(?![0-9A-Z\\_!@#$%^&*`~()-+=]+$)[a-zA-Z0-9\\_!@#$%^&*`~()-+=]{10,16}$/
		if (!reg.test(psd)) {
			return false;
		} else {
			return true;
		}
	},
	cardVerify(code) {//身份证校验
		let list = [];
		let result = true;
		let msg = '';
		var city = {
			11: "北京",
			12: "天津",
			13: "河北",
			14: "山西",
			15: "内蒙古",
			21: "辽宁",
			22: "吉林",
			23: "黑龙江 ",
			31: "上海",
			32: "江苏",
			33: "浙江",
			34: "安徽",
			35: "福建",
			36: "江西",
			37: "山东",
			41: "河南",
			42: "湖北 ",
			43: "湖南",
			44: "广东",
			45: "广西",
			46: "海南",
			50: "重庆",
			51: "四川",
			52: "贵州",
			53: "云南",
			54: "西藏 ",
			61: "陕西",
			62: "甘肃",
			63: "青海",
			64: "宁夏",
			65: "新疆",
			71: "台湾",
			81: "香港",
			82: "澳门",
			91: "国外 "
		};
		if (!validatenull(code)) {
			if (code.length == 18) {
				if (!code || !/(^\d{18}$)|(^\d{17}(\d|X|x)$)/.test(code)) {
					result = false;
					msg = "证件号码格式错误";
				} else if (!city[code.substr(0, 2)]) {
					result = false;
					msg = "地址编码错误";
				} else {
					//18位身份证需要验证最后一位校验位
					code = code.split('');
					//∑(ai×Wi)(mod 11)
					//加权因子
					var factor = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
					//校验位
					var parity = [1, 0, 'X', 9, 8, 7, 6, 5, 4, 3, 2, 'x'];
					var sum = 0;
					var ai = 0;
					var wi = 0;
					for (var i = 0; i < 17; i++) {
						ai = code[i];
						wi = factor[i];
						sum += ai * wi;
					}
					if (parity[sum % 11] != code[17]) {
						result = false;
						msg = "证件号码校验位错误";
					}
				}
			} else {
				result = false;
				msg = "证件号码长度不为18位";
			}
		} else {
			result = false;
			msg = "证件号码不能为空";
		}
		list.push(result);
		list.push(msg);
		return list;
	},

	handleIdCard(userCard, num) {
		//获取出生日期
		if (num == 1) {
			let birth = userCard.substring(6, 10) + '-' + userCard.substring(10, 12) + '-' + userCard.substring(12,
				14)
			return birth
		}
		//获取年龄
		if (num == 2) {
			let myDate = new Date()
			let month = myDate.getMonth() + 1
			let day = myDate.getDate()
			let age = myDate.getFullYear() - userCard.substring(6, 10) - 1
			if (userCard.substring(10, 12) < month || (userCard.substring(10, 12) == month && userCard.substring(12,
				14) <= day)) {
				age++
			}
			if (age <= 0) {
				age = 1
			}
			return age
		}
		//获取性别
		if (num == 3) {
			if (parseInt(userCard.substr(16, 1)) % 2 == 1) {
				//男
				return '1'
			} else {
				//女
				return '2'
			}
		}
	},
	removeStorage() {
		localStorage.removeItem('token')
		localStorage.removeItem('userInfo')
		localStorage.removeItem('resource')
		localStorage.removeItem('menu')

		store.commit('$setState', {
			key: 'userInfo',
			value: {},
		})
		store.commit('$setState', {
			key: 'resource',
			value: {
				treeList: [],
			},
		})
		store.commit('$setState', {
			key: 'tree',
			value: {
				menuList: [],
				routeList: [],
				buttonAuthList: [],
			},
		})
	},
	setVuexResourceMenu(path) {
		// if (path !== '/register' && localStorage.getItem('token') && !store.state.userInfo.entityCode) {
		if (path !== '/register' && localStorage.getItem('token')) {
			// 页面刷 导致store.state数据丢失 需要重新赋值
			if (localStorage.getItem('userInfo')) {
				store.commit('$setState', {
					key: 'userInfo',
					value: JSON.parse(localStorage.getItem('userInfo'))
				})
			}
			if (localStorage.getItem('resource')) {
				store.commit('$setState', {
					key: 'resource',
					value: JSON.parse(localStorage.getItem('resource'))
				})
			}
			if (localStorage.getItem('menu')) {
				store.commit('$setState', {
					key: 'menu',
					value: JSON.parse(localStorage.getItem('menu'))
				})
			}
			if (localStorage.getItem('systemEntity')) {
				store.commit('$setState', {
					key: 'systemEntity',
					value: JSON.parse(localStorage.getItem('systemEntity'))
				})
			}
			if (localStorage.getItem('options')) {
				store.commit('$setState', {
					key: 'options',
					value: JSON.parse(localStorage.getItem('options'))
				})
			}
		}
	}
}

export default base
