let formValidator = (rule, value, callback) => {
    // 公共校验方式重写  解决有值却提示不能为空问题
    if (!value) {
        callback(new Error('不能为空'));
    } else {
        callback();
    }
}
let statusEM = {
    "101000": '待生成项目',
    "101010": '待生成项目部分保存',
    "106000": '待需求公示',
    "111000": '采购文件待制作',
    "111010": '采购文件部分保存',
    "111100": '采购文件待审核',
    "111150": '采购文件待批注',
    "111200": '待转外审',
    "111250": '采购文件审核失败',
    "116200": '文件待转采购人确认',
    "116250": '采购人确认不通过',
    "116300": '文件待采购人确认',
    "121000": '采购公告待发布',
    "201000": '待开标',
}
let util = {
    customTableRender(h, comName, propsObj, bindFunObj) {
        let on = {};
        for (let [k, v] of Object.entries(bindFunObj)) {
            on[k] = v;
        }
        let props = {}
        for (let [k, v] of Object.entries(propsObj)) {
            props[k] = v;
        }

        return h(comName, {
            props,
            on,
        });
    },
    async timeDelay(time) {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve()
            }, time)
        })
    },
    async waitStatus(fun) {
        /* eslint-disable */
        while (true) {
            if (fun()) {
                return
            }
            util.timeDelay(0.1 * 1000)
        }
    },
    form: {
        validate: async (name, _this) => {
            return await _this.$refs[name].validate((valid) => {
                return new Promise((resolve) => {
                    resolve(valid)
                })
            })
        },
        inputRule: { required: true, validator: formValidator, trigger: 'blur' },
        // inputRule: { required: true, message: '不能为空', trigger: 'blur' },
        positiveNumber: { type: 'number', message: '请输入大于零的数字', transform(value) { return Number(value) }, min: 0 },
        idCard: { required: true, pattern: /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{7}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}$)/, message: '请输入正确的身份证号', trigger: 'blur' },
        phone: { required: true, pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/, message: '请输入正确的手机号码', trigger: 'blur' },
        tel: { required: true, pattern: /^((0\d{2,3}-?\d{7,8})|(1[3465789]\d{9}))$/, message: '请输入正确的手机号或座机号', trigger: 'blur' },
        creditCode: { required: true, pattern: /^([0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}|[1-9]\d{14})$/, message: '请输入正确的统一社会信用代码', trigger: 'blur' },
        postCode: { required: true, pattern: /^[0-8][0-7]\d{4}$/, message: '请输入正确的邮政编码', trigger: 'blur' },
        email: { required: true, pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, message: '请输入正确的邮箱', trigger: 'blur' }
    },
    dateFormat(fmt, date) {
        let ret;
        const opt = {
            "Y+": date.getFullYear().toString(),        // 年
            "m+": (date.getMonth() + 1).toString(),     // 月
            "d+": date.getDate().toString(),            // 日
            "H+": date.getHours().toString(),           // 时
            "M+": date.getMinutes().toString(),         // 分
            "S+": date.getSeconds().toString()          // 秒
            // 有其他格式化字符需求可以继续添加，必须转化成字符串
        };
        for (let k in opt) {
            ret = new RegExp("(" + k + ")").exec(fmt);
            if (ret) {
                fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
            };
        };
        return fmt;
    },
    getTableHeight(documentHeight, serchHeight, pagetationHeight, otherHeight) {
        return documentHeight - serchHeight - pagetationHeight - otherHeight
    },
    getStatusContent(val) {
        return statusEM[val]
    }
}
export default util

// 二进制文件下载
export function down(response, fileName = '') {
    const blob = response.data
    const aEle = document.createElement('a')
    const binaryData = []
    binaryData.push(blob)
    console.log(response, '--------blob')
    if (!fileName) {
        // 没传文件名，就用后台的filename， 后台也没有传就。。。。
        const resHeader = response.headers['content-disposition']
        if (resHeader === undefined) {
            console.error('数据为 undefined，无法导出！')
            $this.$Message.warning('数据为 undefined，无法导出！')
            return
        }
        if (resHeader.indexOf('filename=') !== -1) {
            fileName = resHeader.split('filename=')[1]
        } else {
            fileName = resHeader.split('fileName=')[1]
        }
        fileName = decodeURIComponent(fileName || '')
    }
    // IE浏览器
    if (navigator.msSaveBlob) {
        return navigator.msSaveBlob(new Blob(binaryData), fileName)
    }
    aEle.href = window.URL.createObjectURL(new Blob(binaryData))
    aEle.download = fileName
    aEle.click()
    window.URL.revokeObjectURL(aEle.href)
}


export function GetQueryString(name) {//截取url后的参数
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    if (window.location.href.split("?") && window.location.href.split("?").length > 1) {
        var r = window.location.href.split("?")[1].match(reg); //获取url中"?"符后的字符串并正则匹配
        var context = "";
        if (r != null) context = r[2];
        reg = null;
        r = null;
        return context == null || context == "" || context == "undefined" ? "" : context;
    } else {
        return "";
    }
}

export function emptyFormParam(form) {//清空form
    try {
        for (var i in form) {
            //默认只有两种类型 数组 和 string typeof 数据检查为object
            if ((typeof form[i]) == 'object') {
                form[i] = [];
            } else {
                form[i] = "";
            }
        }
    } catch (err) {
        console.log(err);
    }
}

export function gridDataFormate(row, column, dicData) {//表格数据格式化,针对key-value格式为：[{dicValue:'',dicName:''}]
    let results = row[column.property]; // 默认原真实值
    if (dicData && dicData.length > 0) {
        for (let i = 0; i < dicData.length; i++) {
            let item = dicData[i];
            if (row[column.property] === item.dicValue) {
                results = item.dicName;
                break;
            }
        }
    }
    return results;
}

export function setFormParam(form, data) {//表单数据赋值
    try {
        for (var i in form) {
            var key = i;
            var value = data[i];
            if (!validatenull(value)) {
                //默认只有两种类型 数组 和 string typeof 数据检查为object
                if ((typeof form[i]) == 'object') {
                    form[i] = (typeof value) !== 'String' ? value : value.split(",");
                } else {
                    form[i] = value;
                }
            }
        }
    } catch (err) {
        console.log(err);
    }
}

export function validatenull(val) {//判断是否为空
    if (typeof val == 'boolean') {
        return false;
    }
    if (typeof val == 'number') {
        return false;
    }
    if (val instanceof Array) {
        if (val.length == 0) return true;
    } else if (val instanceof Object) {
        if (JSON.stringify(val) === '{}') return true;
    } else {
        if (val == 'null' || val == null || val == 'undefined' || val == undefined || val == '') return true;
        return false;
    }
    return false;
}

//数字格式化（默认保留整数）
//len:要格式化的小数位数(传入0和正整数)，不传默认取整，round:是否执行四舍五入
export function numberFormate(dataNum, len, round) {
    if (round) {
        const numData = dataNum ? parseFloat(dataNum) : 0;
        const resultData = (validatenull(len) || len < 0) ? numData.toFixed(0) : numData.toFixed(parseInt(len, 10));
        return resultData;
    } else {
        let resultData = 0;
        if (dataNum && typeof (dataNum) == 'string') {
            const numData = dataNum.split(".");
            if (numData) {
                if (numData.length == 2) {
                    if (numData[1].length <= len) {
                        resultData = parseFloat(dataNum)
                    } else {
                        resultData = parseFloat(numData[0] + '.' + numData[1].substring(0, len))
                    }
                } else if (numData.length == 1) {
                    resultData = parseFloat(numData[0])
                }
            }
        } else if (dataNum && typeof (dataNum) == 'number') {
            resultData = dataNum;
        }
        return resultData;
    }
}

/**
 * Author:chengbing
 * 加法函数，用来得到精确的加法结果
 * 说明：javascript的加法结果会有误差，在两个浮点数相加的时候会比较明显。这个函数返回较为精确的加法结果。
 * 返回值：arg1加上arg2的精确结果
 */
export function addNum(arg1, arg2) {
    if (arg1 == null) {
        arg1 = "0.0";
    }
    if (arg2 == null) {
        arg2 = "0.0";
    }
    var r1, r2, m, c;
    try {
        r1 = arg1.toString().split(".")[1].length;
    }
    catch (e) {
        r1 = 0;
    }
    try {
        r2 = arg2.toString().split(".")[1].length;
    }
    catch (e) {
        r2 = 0;
    }
    c = Math.abs(r1 - r2);
    m = Math.pow(10, Math.max(r1, r2));
    if (c > 0) {
        var cm = Math.pow(10, c);
        if (r1 > r2) {
            arg1 = Number(arg1.toString().replace(".", ""));
            arg2 = Number(arg2.toString().replace(".", "")) * cm;
        } else {
            arg1 = Number(arg1.toString().replace(".", "")) * cm;
            arg2 = Number(arg2.toString().replace(".", ""));
        }
    } else {
        arg1 = Number(arg1.toString().replace(".", ""));
        arg2 = Number(arg2.toString().replace(".", ""));
    }
    return (arg1 + arg2) / m;
}

/**
 * Author:chengbing
 * 减法函数，用来得到精确的减法结果
 * 说明：javascript的减法结果会有误差，在两个浮点数相减的时候会比较明显。这个函数返回较为精确的减法结果。
 * 返回值：arg1加上arg2的精确结果
 */
export function subNum(arg1, arg2) {
    var r1, r2, m, n;
    try {
        r1 = arg1.toString().split(".")[1].length;
    }
    catch (e) {
        r1 = 0;
    }
    try {
        r2 = arg2.toString().split(".")[1].length;
    }
    catch (e) {
        r2 = 0;
    }
    m = Math.pow(10, Math.max(r1, r2)); //last modify by deeka //动态控制精度长度
    n = (r1 >= r2) ? r1 : r2;
    return parseFloat(((arg1 * m - arg2 * m) / m).toFixed(n));
}

export function uuid(len, radix) {//生成随机id
    var chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
    var uuid = [], i;
    radix = radix || chars.length;

    if (len) {
        // Compact form
        for (i = 0; i < len; i++) uuid[i] = chars[0 | Math.random() * radix];
    } else {
        // rfc4122, version 4 form
        var r;

        // rfc4122 requires these characters
        uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
        uuid[14] = '4';

        // Fill in random data. At i==19 set the high bits of clock sequence as
        // per rfc4122, sec. 4.1.5
        for (i = 0; i < 36; i++) {
            if (!uuid[i]) {
                r = 0 | Math.random() * 16;
                uuid[i] = chars[(i == 19) ? (r & 0x3) | 0x8 : r];
            }
        }
    }

    return uuid.join('');
}

//获取当前时间 格式：yyyy-MM-dd HH:MM:SS
export function getNowFormatDate(date) {
    if (!date) {
        var date = new Date();
    }
    var seperator1 = "-";
    var seperator2 = ":";
    var month = date.getMonth() + 1;
    var strDate = date.getDate();
    var HH = date.getHours();
    var MM = date.getMinutes();
    var SS = date.getSeconds();
    if (month >= 1 && month <= 9) {
        month = "0" + month;
    }
    if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
    }
    if (HH >= 0 && HH <= 9) {
        HH = "0" + HH;
    }
    if (MM >= 0 && MM <= 9) {
        MM = "0" + MM;
    }
    if (SS >= 0 && SS <= 9) {
        SS = "0" + SS;
    }
    var currentdate = date.getFullYear() + seperator1 + month + seperator1 + strDate
        + " " + HH + seperator2 + MM
        + seperator2 + SS;
    return currentdate;
}


//获取当前时间 格式：yyyy年MM月dd日 HH点MM分
export function formatDateTime(date) {
    if (!date) {
        var date = new Date();
    }
    var month = date.getMonth() + 1;
    var strDate = date.getDate();
    var HH = date.getHours();
    var MM = date.getMinutes();
    var SS = date.getSeconds();
    if (month >= 1 && month <= 9) {
        month = "0" + month;
    }
    if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
    }
    if (HH >= 0 && HH <= 9) {
        HH = "0" + HH;
    }
    if (MM >= 0 && MM <= 9) {
        MM = "0" + MM;
    }
    if (SS >= 0 && SS <= 9) {
        SS = "0" + SS;
    }
    var currentdate = date.getFullYear() + '年' + month + '月' + strDate + "日" + HH + ':' + MM + ':' + SS;
    return currentdate;
}

/**
 * Author:lizl
 * 计算两个时间查
 * date1: 开始时间 格式 yyyy-MM-dd hh:mm:ss
 * date2: 结束时间 格式 yyyy-MM-dd hh:mm:ss
 *
 */
export function getDateDiff(date1, date2) {
    let date3 = new Date(date2).getTime() - new Date(date1).getTime();
    //计算出相差天数
    let days = Math.floor(date3 / (24 * 3600 * 1000))
    //计算出小时数
    let leave1 = date3 % (24 * 3600 * 1000)    //计算天数后剩余的毫秒数
    let hours = Math.floor(leave1 / (3600 * 1000))
    //计算相差分钟数
    let leave2 = leave1 % (3600 * 1000)        //计算小时数后剩余的毫秒数
    let minutes = Math.floor(leave2 / (60 * 1000))
    //计算相差秒数
    let leave3 = leave2 % (60 * 1000)      //计算分钟数后剩余的毫秒数
    let seconds = Math.round(leave3 / 1000)
    let result = "";
    if (days > 0) {
        result += days + "天";
    }
    if (hours > 0) {
        result += hours + "小时";
    }
    if (seconds > 0) {
        result += seconds + "分";
    }
    return result;
}

//获取当前时间num天后的时间 格式：yyyy-MM-dd HH:MM:SS
export function getBeforeOrAfterNowFormatDate(time, num) {
    var date = time ? new Date(new Date(time).getTime()) : new Date();
    date.setDate(addNum(date.getDate(), num ? num : 0));//获取num天后的日期
    var seperator1 = "-";
    var seperator2 = ":";
    var month = date.getMonth() + 1;
    var strDate = date.getDate();
    if (month >= 1 && month <= 9) {
        month = "0" + month;
    }
    if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
    }
    var currentdate = date.getFullYear() + seperator1 + month + seperator1 + strDate
        + " " + date.getHours() + seperator2 + date.getMinutes()
        + seperator2 + date.getSeconds();
    return currentdate;
}

//从当前时间开始倒计时 
export function decodeCountDown(countDownTime) {
    let hoursDate = 0;
    let minutesDate = '';
    let secondsDate = '';
    let timeFlag = null;
    let date = new Date(countDownTime).getTime() - new Date().getTime();

    let days = Math.floor(date / (24 * 3600 * 1000))//计算出相差天数

    let leave1 = date % (24 * 3600 * 1000)//计算出小时数
    if (days > 0) {
        hoursDate = days * 24 + Math.floor(leave1 / (3600 * 1000))
    } else {
        if (Math.floor(leave1 / (3600 * 1000)) >= 1 && Math.floor(leave1 / (3600 * 1000)) <= 9) {
            hoursDate = '0' + Math.floor(leave1 / (3600 * 1000))
        } else if (Math.floor(leave1 / (3600 * 1000)) > 9) {
            hoursDate = Math.floor(leave1 / (3600 * 1000))
        } else {
            hoursDate = 0;
        }
    }

    let leave2 = leave1 % (3600 * 1000)//计算相差分钟数
    if (Math.floor(leave2 / (60 * 1000)) >= 1 && Math.floor(leave2 / (60 * 1000)) <= 9) {
        minutesDate = '0' + Math.floor(leave2 / (60 * 1000))
    } else if (Math.floor(leave2 / (60 * 1000)) > 9) {
        minutesDate = Math.floor(leave2 / (60 * 1000))
    } else {
        minutesDate = '00';
    }
    let leave3 = leave2 % (60 * 1000)//计算相差秒数
    if (Math.round(leave3 / 1000) >= 1 && Math.round(leave3 / 1000) <= 9) {
        secondsDate = '0' + Math.round(leave3 / 1000);
    } else if (Math.round(leave3 / 1000) > 9) {
        secondsDate = Math.round(leave3 / 1000);
    } else {
        secondsDate = '00';
    }
    if (getNowFormatDate() < countDownTime) {
        timeFlag = true;
    } else {
        timeFlag = false
    }
    return { hoursDate, minutesDate, secondsDate, timeFlag }
}


export function treeMenu(rows) {
    function exists(rows, parentId) {
        for (var i = 0; i < rows.length; i++) {
            if (rows[i].id === parentId) return true;
        }
        return false
    }
    let nodes = []
    for (let i = 0; i < rows.length; i++) {
        let row = rows[i]
        if (!exists(rows, row.parentId)) {
            nodes.push({
                id: row.id,
                parentId: row.parentId,
                level: row.level,
                title: row.title,
                resourceType: row.resourceType,
                url: row.url,
                ifShow: row.ifShow,
                sort: row.sort,
                icon: row.icon,
                roleType: row.roleType,
                open: false,
                isChecked: row.isChecked
            })
        }
    }
    let toDo = []
    for (let i = 0; i < nodes.length; i++) {
        toDo.push(nodes[i])
    }
    while (toDo.length) {
        let node = toDo.shift()
        for (let i = 0; i < rows.length; i++) {
            let row = rows[i]
            if (row.parentId === node.id) {
                let child = {
                    id: row.id,
                    parentId: row.parentId,
                    level: row.level,
                    title: row.title,
                    resourceType: row.resourceType,
                    url: row.url,
                    ifShow: row.ifShow,
                    sort: row.sort,
                    icon: row.icon,
                    roleType: row.roleType,
                    open: false,
                    isChecked: row.isChecked,
                    children: []
                }
                if (node.children) {
                    node.children.push(child)
                } else {
                    node.children = [child]
                }
                toDo.push(child)
            }
        }
    }
    return nodes;
}

//是否IE浏览器
export function isIE(time) {
    if (!!window.ActiveXObject || "ActiveXObject" in window) return true;
    else return false;
}

/**
 * 浏览器判断是否全屏
 */
export const fullscreenToggel = () => {
    if (fullscreenEnable()) {
        exitFullScreen();
    } else {
        reqFullScreen();
    }
};
/**
 * esc监听全屏
 */
export const listenfullscreen = (callback) => {
    function listen() {
        callback()
    }
    document.addEventListener("fullscreenchange", function() {
        listen();
    });
    document.addEventListener("mozfullscreenchange", function() {
        listen();
    });
    document.addEventListener("webkitfullscreenchange", function() {
        listen();
    });
    document.addEventListener("msfullscreenchange", function() {
        listen();
    });
};
/**
 * 浏览器判断是否全屏
 */
export const fullscreenEnable = () => {
    // var isFullscreen = document.fullScreenElement//W3C
    //     ||document.msFullscreenElement //IE
    //     ||document.mozFullScreenElement //火狐
    //     ||document.webkitFullscreenElement //谷歌
    //     ||false;

    // return isFullscreen;
    let isFull =
        document.mozFullScreen ||
        document.fullScreen ||
        //谷歌浏览器及Webkit内核浏览器
        document.webkitIsFullScreen ||
        document.webkitRequestFullScreen ||
        document.mozRequestFullScreen ||
        document.msFullscreenEnabled;
      if (isFull === undefined) {
        isFull = false;
      }
      return isFull;

}
/**
 * 浏览器全屏
 */
export const reqFullScreen = () => {
    if (document.documentElement.requestFullScreen) {
        document.documentElement.requestFullScreen();
    } else if (document.documentElement.webkitRequestFullScreen) {
        document.documentElement.webkitRequestFullScreen();
    } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
    }
};
/**
 * 浏览器退出全屏
 */
export const exitFullScreen = () => {
    if (document.documentElement.requestFullScreen) {
        document.exitFullScreen();
    } else if (document.documentElement.webkitRequestFullScreen) {
        document.webkitCancelFullScreen();
    } else if (document.documentElement.mozRequestFullScreen) {
        document.mozCancelFullScreen();
    }
};