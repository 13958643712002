<template>
    <div :class="{'tab-active': $parent.value === value}" class="tabs-tab" @click="clickPane">
        <slot />
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: String,
        },
    },
    components: {},
    data() {
        return {}
    },
    computed: {},
    watch: {},
    methods: {
        clickPane() {
            this.$parent.switchTab(this.value)
        },
    },
    mounted() {},
}
</script>

<style scoped>
.tabs-tab {
    display: block;
    width: 100%;
    height: 100%;
    padding: 8px 16px;
    margin-right: 5px;
    box-sizing: border-box;
    cursor: pointer;
    text-decoration: none;
    position: relative;
    transition: color 0.3s ease-in-out;
}
.tab-active {
    color: #2d8cf0;
    border-right: 3px solid #2d8cf0;
}
</style>
