<template>
    <div>
        <el-collapse-transition>
            <div class="cur-menu el-aside" style="width:256px">
                <el-menu class="el-menu-vertical-demo" unique-opened :default-active="nowTagValue" :collapse-transition="false">
                    <template v-for="(item, index) of this.leftMenu">
                        <menuItem :item="item" :index="item.url ? item.url : item.title"></menuItem>
                    </template>
                </el-menu>
            </div>
        </el-collapse-transition>
        <!-- <el-collapse-transition v-else>
            <div class="unfold" @mousedown="startDrag" @mouseup="handleMouseUp" :style="{ top: top + 'px' }">
                <i class="el-icon-s-unfold" @click="setCollapse(keyCollapse)"></i>
            </div>
        </el-collapse-transition> -->
    </div>
</template>
<script>
import menuItem from './menuItem.vue'
export default {
    components: { menuItem },
    props: {
        leftMenu: []
    },
    data() {
        return {
            firstTime: null,
            lastTime: null,
            dragging: false,
            incidentType: 'click',
            top: 56,
            startY: 0,
            startTop: 0
        }
    },
    computed: {
        nowTagValue () { 
            return this.$router.$menuRouter.getValue(this.$route)
        },
     },
    watch: { },
    created() { },
    mounted() { },
    methods: {
        setCollapse(e){
            if(this.incidentType == 'click'){
                if (e == true) {
                    setTimeout(() => {
                        this.top = 56;
                        let flag = !this.$store.state.keyCollapse
                        this.$store.commit('$setState', { key: 'keyCollapse', value: flag })
                    }, 150);
                } else {
                    let flag = !this.$store.state.keyCollapse
                    this.$store.commit('$setState', { key: 'keyCollapse', value: flag })
                }
            }
        },
        startDrag(event) {//鼠标按下
            this.firstTime = new Date().getTime();
            this.dragging = true;
            this.startY = event.clientY;
            this.startTop = this.top;
            document.addEventListener('mousemove', this.doDrag);
            document.addEventListener('mouseup', this.stopDrag);
        },
        doDrag(event) {
            if (this.dragging) {
                const diffY = event.clientY - this.startY;
                this.top = this.startTop + diffY;
                if (this.top < 0) this.top = 0;
            }
        },
        stopDrag() {
            this.dragging = false;
            document.removeEventListener('mousemove', this.doDrag);
            document.removeEventListener('mouseup', this.stopDrag);
        },
        handleMouseUp(){//鼠标抬起
            this.lastTime = new Date().getTime();
            if ((this.lastTime - this.firstTime) < 200) {
                this.incidentType = 'click';
            } else {
                this.incidentType = 'mousedown';
            }
        }
    },
}
</script>

<style lang='less' scoped>
    .cur-menu {
        min-height: calc(100vh - 58px);
        max-height: calc(100vh - 58px);
        float: left;
        display: block;
        margin: 0;
        padding: 0;
        outline: 0;
        list-style: none;
        color: #515a6e;
        font-size: 14px;
        position: relative;
        z-index: 900;
        background-color: #ffffff;
        overflow-y: auto;
        /deep/ .el-menu{
            border-right: 0;
            .el-menu-item, .el-submenu__title{
                height: 50px;
                line-height: 50px;
            }
        }
    }
    .unfold{
        position: absolute;
        display: inline-block;
        width: 38px;
        height: 60px;
        background: #fff;
        line-height: 60px;
        text-align: center;
        border-radius: 0 20px 20px 0;
        font-size: 18px;
        cursor: pointer;
        border: 1px solid #EBEEF5;
        z-index: 9;
    }
    .el-aside {
        transition: width 0.15s;
        -webkit-transition: width 0.15s;
        -moz-transition: width 0.15s;
        -webkit-transition: width 0.15s;
        -o-transition: width 0.15s;
    }
    .el-menu-vertical-demo:not(.el-menu--collapse) {
        border-width: 0;
        text-align: left;
    }
</style>