/*
 * axios 自定义配置  根据项目需求扩展
 */
import axios from 'axios'; // 引入 axios
import { Loading } from "element-ui";
let loadingRequestCount = 0;
let loadingInstance;
export function showLoading() {
	if (loadingRequestCount === 0) {
		loadingInstance = Loading.service({
			fullscreen: true,
			background: 'rgba(255, 255, 255, 0.9)',
			text: '请求加载中，请稍后......',
			customClass: 'loadingclass'
		});
	}
	loadingRequestCount++
}
export function hideLoading() {
	if (loadingRequestCount <= 0) return
	loadingRequestCount--
	if (loadingRequestCount === 0) {
		loadingInstance.close();
	}
}
export function fetch(options, needLoading, timeout) {
	if (needLoading !== false) {
		showLoading()
	}
	return new Promise((resolve, reject) => {
		// header设置
		let headers = {// 内容类型 设置为 序列化后的JSON字符串 需要其他类型直接在对应的api方法中设置即可 会在options中展现
			'Content-Type': 'application/json' // 告诉服务端消息主体是序列化后的 JSON 字符串
		}
		let noTokenApiArr = [// token 相关
			'/czy-uaa/tenant/getTenantList',
			'/czy-system/system/baseCode/roleGroupCode',
			'/czy-system/system/baseCode/systemRootCode',
			'/czy-uaa/tenantEntity/listTenantEntityTypeByTenantCode',
			'/czy-system/platform/getBusinessPlatforms',
			'/czy-uaa/entity/addEntity'
		]
		let token = localStorage.getItem('token')
		
		if (token && noTokenApiArr.indexOf(options.url) < 0) {// 有token 且 请求地址不在不需token内
			headers['Authorization'] = token
		}
		const instance = axios.create({// instance 创建一个 axios 实例，可以自定义配置，可在 axios 文档中查看详情
			headers: headers,
			timeout: timeout || 30 * 1000 //30秒超时
		});
		instance(options).then(response => {
			if (needLoading !== false) {
				setTimeout(() => {
					hideLoading()
				}, 200);
			}
			$this.$loading.hide() // loading 目前分 table.loading 和 frame.vue中定义的$loading TODO 应该改为具体调用逻辑中回调关闭
			if (options.responseType == "blob") {// 下载、导出接口返回二进制流
				return resolve(response)
			}
			if (response.config.url.indexOf('bid/quotation/decryptFirstPriceAndFileResult') > -1) {// 一次解密
				return resolve(response)
			}
			let responseData = response.data.body.data; // 默认返回请求成功后的body.data数据
			if (response.status && response.status == 200) {
				if (!response.data.head.success) {
					if ([401, 453].includes(response.data.head.code)) {
						base.removeStorage()
						$this.$Message.warning('登录信息已失效')
						if (!['/', '/login'].includes($this.$route.path)) {
							// let nextHref = encodeURIComponent($this.$route.path)
							// $this.$router.push({
							// 	path: `/login?next=${nextHref}`,
							// })
							let url = `${location.origin}/#/login`
							window.open(url, '_self')
						}
					} else {
						$this.$Message.warning(response.data.head.msg)
					}

				} else if (!response.data.body.success) {
					responseData = response.data.body// 返回完整body数据
					if (response.data.body.msg) {
						$this.$Message.error(response.data.body.msg);
					} else {
						$this.$Message.warning('接口返回出错')
					}
				}
				resolve(responseData) // 把请求到的数据发到引用请求的地方
			} else {
				$this.$Message.warning('接口返回出错')
			}
		}).catch(error => {
			if (needLoading !== false) {
				setTimeout(() => {
					hideLoading()
				}, 200);
			}
			$this.$loading.hide(); // TODO 应该改为具体调用逻辑中回调关闭
			reject(error);// 把错误信息发到引用请求的地方 特殊请求错误 应该自己写catch 接收这里的信息 进行处理
			// 公共错误处理逻辑
			if (!error.response) {
				$this.$Message.error('系统正忙,请稍后再试!')
			} else if (!error.response.data.head) {
				$this.$Message.error(error.response.error || error.response.data.msg || `异常信息${error}`)
			} else if (error.response.data.head.msg) {
				$this.$Message.error(error.response.data.head.msg)
				if (error.response.status === 405) {
					if (!['/', '/login'].includes($this.$route.path)) {
						// let nextHref = encodeURIComponent($this.$route.path)
						// $this.$router.push({
						// 	path: `/login?next=${nextHref}`
						// })
						let url = `${location.origin}/#/login`
						window.open(url, '_self')
					}
				}
			} else {
				$this.$Message.error('系统正忙,请稍后再试!');
			}
		});
	});
}
