<!-- 标题组件 -->
<template>
	<div class="mb30">
		<div class="titleboxCCQ" style="height: auto">
			<div :class="needMust ? 'mustCCQ pl10' : ''" class="fl">
				<span v-if="slotShow"> <slot name="header"></slot> </span>
				<span v-else> {{ label }} </span>
			</div>
			<div class="fr mr10" v-if="needShow">
				<el-button type="text" style="padding: 0" @click="show = !show">{{ show ? '收起' : '展开' }}</el-button>
			</div>
			<div class="fr mr10" v-if="label2">
				<span v-if="slotShow2"> <slot name="header2"></slot> </span>
				<span v-else :class="lablecolor ? lablecolor : ''"> {{ label2 }} </span>
			</div>
			<div class="cb"></div>
		</div>
		<el-collapse-transition>
			<div v-show="show">
				<div class="mt25">
					<slot></slot>
				</div>
			</div>
		</el-collapse-transition>
	</div>
</template>
<script>
export default {
	components: {},
	name: '',
	props: {
		label: {}, //标签标题
		label2: {}, //副标签
		needShow: {
			//是否需要展开收起
			type: Boolean,
			default: false
		},
		needMust: {
			//*必填星号标记
			type: Boolean,
			default: false
		},
		slotShow: {
			//标签插槽
			type: Boolean,
			default: false
		},
		slotShow2: {
			//
			type: Boolean,
			default: false
		},
		lablecolor: String
	},
	data() {
		return {
			show: true
		}
	},
	computed: {},
	watch: {},
	methods: {},
	//生命周期 - 创建完成（可以访问当前this实例）
	created() {},
	//生命周期 - 挂载完成（可以访问DOM元素）
	mounted() {},
	//生命周期 - 创建之前
	beforeCreate() {},
	//生命周期 - 挂载之前
	beforeMount() {},
	//生命周期 - 更新之前
	beforeUpdate() {},
	//生命周期 - 更新之后
	updated() {},
	//生命周期 - 销毁之前
	beforeDestroy() {},
	//生命周期 - 销毁完成
	destroyed() {},
	//如果页面有keep-alive缓存功能，这个函数会触发
	activated() {}
}
</script>
<style lang="less" scoped>
//@import url(); 引入公共css类
.red {
	color: red;
}
</style>
