<template>
    <Modal @on-cancel="cancel" v-model="showModal" title="选择图标" width="1000">
        <div>
            <Icon v-for="item in iconList" :key="item" :class="{red: icon === item}" class="icon-item" @click="selectIcon(item)" :type="item" style="margin-right:10px;" size="20" />
        </div>
        <div slot="footer">
            <Button type="text" @click="cancel">取消</Button>
        </div>
    </Modal>
</template>

<script>
import iconList from './iviewIcon.json'

export default {
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        icon: {
            type: String,
            value: '',
        },
    },
    components: {},
    data() {
        return {
            iconList,
        }
    },
    computed: {
        showModal: {
            get() {
                return this.value
            },
            set(newVal) {
                this.$emit('input', newVal)
            },
        },
    },
    watch: {},
    methods: {
        cancel() {
            this.showModal = false
        },
        selectIcon(item) {
            this.$emit('update:icon', item)
            this.showModal = false
        },
    },
    mounted() {},
}
</script>

<style scoped>
.icon-item {
    display: inline-block;
    width: 20px;
    padding: 5px 20px;
    cursor: pointer;
    line-height: 32px;
}
.icon-item:hover {
    color: #2d8cf0;
}
.red {
    color: red;
}
</style>
