import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';

import 'default-passive-events'//谷歌浏览器版本过高，导致控制台出现一些警告
import 'babel-polyfill'
import 'core-js/stable'
import 'regenerator-runtime/runtime'

import 'element-ui/lib/theme-chalk/index.css';
import 'font-awesome/css/font-awesome.css'
import '@/assets/css/global.css'

import base from '@/views/base.js'
Vue.prototype.$b = base;

import '@/plugin/window.js'
import '@/plugin/base.js'
import '@/plugin/project.js'
import '@/plugin/emoji.js';

Vue.use(ElementUI);

ElementUI.Dialog.props.appendToBody.default = true;//设置弹框插入位置

ElementUI.Dialog.props.closeOnClickModal.default = false;//设置弹框点击modal不可关闭

ElementUI.Button.props.size.default = 'small';//设置按钮大小

Vue.config.productionTip = false

import highcharts from 'highcharts'
import highcharts3d from 'highcharts/highcharts-3d'
highcharts3d(highcharts)

if (!("classList" in document.documentElement)) {
    Object.defineProperty(HTMLElement.prototype, 'classList', {
        get: function () {
            var self = this;
            function update(fn) {
                return function (value) {
                    var classes = self.className.split(/\s+/g),
                        index = classes.indexOf(value);
                    fn(classes, index, value);
                    self.className = classes.join(" ");
                }
            }
            return {
                remove: update(function (classes, index) {
                    if (~index) classes.splice(index, 1);
                }),
                add: update(function (classes, index, value) {
                    if (~index) classes.splice(index, 0, value);
                })
            };
        }
    });
}

Vue.directive('title', {
    inserted: function (e) {
        document.title = e.dataset.title
    }
})

let app = new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')

window.$this = app