<template>
    <el-submenu v-if="item.children && item.children.length > 0" :index="item.url ? item.url : item.title">
        <template slot="title">
            <span slot="title">{{item.title}}</span>
        </template>
        <template v-for="(row, i) of item.children">
            <menuItem :item="row" :index="item.url ? item.url : item.title"></menuItem>
        </template>
    </el-submenu>
    <el-menu-item v-else :index="item.url ? item.url : item.title" :class="{ 'cur-menu-item-selected': item.url == nowTagValue }" 
        @click="open(item)">
        <span slot="title">{{item.title}}</span>
        <i v-if="item.icon" :class="item.icon" class="Collapse" @click.stop="handleCollapse"></i>
    </el-menu-item>
</template>

<script>
export default {
    name: 'menuItem',
    components: {},
	inject: ['reload'],
    props: {
        item: {
            required: true,
            type: Object,
        },
    },
    data() {
        return {}
    },
    computed: {
        nowTagValue () { 
            return this.$router.$menuRouter.getValue(this.$route)
        },
    },
    watch: {},
    methods: {
        open(item) {
            if (this.$route.path == item.url) return;
            this.$router.push({ path: item.url });
        },
        handleCollapse(){
            // this.$store.commit('$setState', { key: 'keyCollapse', value: !this.keyCollapse })
        }
	},
    mounted() {},
}
</script>

<style lang='less' scoped>
.cur-menu-submenu-title {
    padding: 14px 24px;
    position: relative;
    cursor: pointer;
    z-index: 1;
    transition: all 0.2s ease-in-out;
}
.cur-menu-submenu-title span > i,
.cur-menu-submenu-title > i {
    margin-right: 6px;
}
.cur-menu-submenu-title-icon {
    position: absolute;
    top: 50%;
    right: 24px;
    transform: translateY(-50%);
}
.cur-menu-opened > * > .cur-menu-submenu-title-icon {
    transform: translateY(-50%) rotate(180deg);
}

.cur-menu {
    display: block;
    margin: 0;
    padding: 0;
    outline: 0;
    list-style: none;
    color: #515a6e;
    font-size: 14px;
    position: relative;
    z-index: 900;
    background-color: #ffffff;
   
}
.cur-menu-submenu-title {
    padding: 14px 24px;
    position: relative;
    cursor: pointer;
    z-index: 1;
    transition: all 0.2s ease-in-out;
}
.cur-menu-item {
    position: relative;
    padding-left: 27px;
    padding-top: 14px;
    padding-bottom: 14px;
    display: block;
    outline: 0;
    list-style: none;
    font-size: 14px;
    position: relative;
    z-index: 1;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    color: #515a6e;
}
.cur-menu-item:hover {
    color: #2d8cf0;
}
.cur-menu-item.cur-menu-item-selected:hover {
    color: #ffffff;
}
.cur-menu-item.cur-menu-item-selected {
    color: #ffffff;
    z-index: 4;
}
.cur-menu-item.cur-menu-item > .bg {
    position: absolute;
    top: 0;
    right: 0;
    width: 256px;
    height: 100%;
    background-color: #ffffff;
    z-index: 2;
}

.cur-menu-item-selected {
    background-color: #2d8cf0 !important;
    color: #fff !important;
}
.Collapse{
    position: absolute;
    right: 17px;
    top: 15px;
    font-size: 18px;
}

</style>