<template>
    <div class="left-tab" @switchTab="switchTab" :style="tabStyle">
        <div class="tabs-nav-container">
            <div class="tabs-nav">
                <slot />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: String,
        },
        paneList: {
            type: Array,
            default: () => {
                return []
            },
        },
        width: {
            type: Number,
            default: 100,
        },
    },
    components: {},
    data() {
        return {}
    },
    computed: {
        tabStyle() {
            let style = {
                width: this.width + 'px',
            }

            return style
        },
    },
    watch: {},
    methods: {
        switchTab(value) {
            this.$emit('input', value)
        },
    },
    mounted() {},
}
</script>

<style scoped>
.left-tab {
    border-right: 1px solid #dcdee2;
    margin-right: 16px;
}
.tabs-nav-container {
    margin-right: -1px;
    line-height: 1.5;
    font-size: 14px;
    box-sizing: border-box;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
    zoom: 1;
}
.tabs-nav {
    padding-left: 0;
    margin: 0;
    list-style: none;
    box-sizing: border-box;
    position: relative;
    transition: -webkit-transform 0.5s ease-in-out;
    transition: transform 0.5s ease-in-out;
    transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
}
</style>
