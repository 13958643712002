import { uuid } from "@/plugin/util";
let RouterPlugin = function () {
    this.$router = null;
};

RouterPlugin.install = function (router) {
    this.$router = router;
    this.$router.$menuRouter = {
        safe: this,//全局配置
        getValue: function(route) {//设置路由值
            let value = "";
            if (route.query.src) {
                value = route.query.src;
            } else {
                value = route.path;
            }
            return value;
        },
        formatRoutes: function (aMenu, first, asyncImport) {//动态路由
            const aRouter = [];
            if (!aMenu) return;
            aMenu.forEach(oMenu => {
                const path = oMenu.url,
                    component = "views" + oMenu.url,
                    name = oMenu.title,
                    icon = oMenu.icon,
                    children = oMenu.children;
                const isChild = children != null && children.length !== 0;
                const oRouter = {
                    path: path ? path : uuid(10),
                    component(resolve) {
                        if (first) {// 判断是否为首路由
                            require(['@/views/frame'], resolve)
                            return
                        } else {// 判断是否为最终的页面视图
                            return import( `@/${component}/index.vue`);
                        }
                    },
                    name: name,
                    icon: icon,
                    redirect: (() => {
                        return ''
                        // if (!isChild && first) return `${path}/index`
                        // else return '';
                    })(),
                    children: !isChild ? (() => {// 处理是否为一级路由
                        if (first) {
                            oMenu.url = `${path}`;
                            return [{
                                component(resolve) {
                                    if (!asyncImport) {
                                        require([`@/${component}/index.vue`], resolve)
                                        return
                                    } else {
                                        return import(`@/${component}.vue`)
                                    }
                                },
                                icon: icon,
                                name: name,
                                path: `${path}`,
                            }]
                        }
                        return [];
                    })() : (() => {
                        return this.formatRoutes(children, false)
                    })()
                }
                aRouter.push(oRouter);
            });
            if (first) {
                this.safe.$router.addRoutes(aRouter)
            } else {
                return aRouter
            }
        },
    }
}
export default RouterPlugin;