<!-- 重置密码 -->
<template>
    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="400px" :show-close="false" :close-on-press-escape="false"
        destroy-on-close custom-class="common-dialog" @close="handleClose">
        <el-form ref="form" :model="form" label-width="120px" label-position="left" :rules="rules" size="small">
            <el-form-item v-if="!backFlag" label="用户名：" prop="username">
                <el-input v-emoji v-model="form.username"></el-input>
            </el-form-item>
            <el-form-item label="原密码：" prop="oldPassword">
                <el-input v-model="form.oldPassword" show-password></el-input>
            </el-form-item>
            <el-form-item label="新密码：" prop="newPassword">
                <el-input v-model="form.newPassword" show-password></el-input>
            </el-form-item>
            <el-form-item label="确认新密码：" prop="confirmPassword">
                <el-input v-model="form.confirmPassword" show-password></el-input>
            </el-form-item>
            <span style="color: #ccc"> 新密码需要包含大小写字母，数字，特殊字符，且密码位数为10到20位。其中特殊字符包含 !@#$%^&*</span>
        </el-form>
        <template slot="footer">
            <el-button @click="dialogVisible = false" size="small">取消</el-button>
            <el-button @click="handleSubmit" type="primary" size="small">确定</el-button>
        </template>
    </el-dialog>
</template>
<script>
import JSEncrypt from "jsencrypt";
import { getPublicKey, resetPwd } from "@/axios/api/main.js";
export default {
    name: '',
    mixins: [],
    data() {
        return {
            loginType: localStorage.getItem("loginType"),
            userInfo: JSON.parse(localStorage.getItem("userInfo")),
            dialogTitle: '重置密码',
            backFlag: false,
            dialogVisible: false,
            form: {
                username: '',
                oldPassword: '',
                newPassword: '',
                confirmPassword: '',
            },
            rules: {
                username: { required: true, message: '用户名不能为空' },
                oldPassword: { required: true, message: '原密码不能为空' },
                newPassword: { required: true, message: '新密码不能为空' },
                confirmPassword: { required: true, message: '确认新密码不能为空' },
            },
        }
    },
    created() { },
    mounted() { },
    methods: {
        showDialog(e) {//显示弹窗
            if (e) {
                this.dialogTitle = e;
                this.backFlag = true;
            }
            this.dialogVisible = true
        },
        handleSubmit() {// 提交
            if (this.form.confirmPassword !== this.form.newPassword) {
                return this.$message.error('新密码和确认新密码不一致')
            }
            this.$refs.form.validate((valid) => {
                if (valid) {
                    getPublicKey().then(async (res) => {
                        if (res && JSON.stringify(res.success) == "false") return;
                        let publicKey = res.publicKey;
                        let encryptor = new JSEncrypt();
                        encryptor.setPublicKey(publicKey);
                        let username = this.backFlag ? this.userInfo.uname : this.form.username;
                        let params = {
                            userName: username,
                            oldPwd: encryptor.encrypt(this.form.oldPassword),
                            newPwd: encryptor.encrypt(this.form.newPassword),
                            publicKey: publicKey,
                            userType: this.loginType,
                        }
                        resetPwd(params).then((res) => {
                            if (res && JSON.stringify(res.success) == "false") {
                                return;
                            }
                            this.$message.success('重置密码成功，请重新登录');
                            this.dialogVisible = false;
                            if (this.backFlag) {
                                setTimeout(() => {
                                    if (localStorage.getItem("loginType") && localStorage.getItem("areaCode")) {
                                        this.$router.push({ path: '/login', query: { loginType: localStorage.getItem("loginType"), zoneId: localStorage.getItem("areaCode") } })
                                    } else if (localStorage.getItem("loginType")) {
                                        this.$router.push({ path: '/login', query: { loginType: localStorage.getItem("loginType") } })
                                    } else if (localStorage.getItem("areaCode")) {
                                        this.$router.push({ path: '/login', query: { zoneId: localStorage.getItem("areaCode") } })
                                    } else {
                                        this.$router.push({ path: '/login' })
                                    }
                                })
                            }
                        }).catch((err) => {
                            console.log(err);
                        });
                    })
                }
            })
        },
        handleClose() {//关闭回调
            this.form = {
                username: '',
                oldPassword: '',
                newPassword: '',
                confirmPassword: '',
            }
        },
    },
}
</script>
<style lang="less" scoped>
</style>