<template>
	<div>
		<!-- 全屏模式 -->
		<div class="clear-float" v-if="keyCollapse" style="width: 100%; background: #f0f2f5;">
			<div class="rightContent" style="width: 100%; margin: 0;">
				<loading v-if="loading" :loadingText="loadingText" />
				<router-view />
				<div class="znkf" @click="gokefu" style="right: 0">
					<p class="kfimg"><img src="../assets/img/kefu.png" alt="" srcset="" /></p>
					<p>智能客服</p>
				</div>
			</div>
		</div>
		<div v-else>
			<!-- 头部 - 从汇集客户端进入供应商首页 - 隐藏 -->
			<topHeader2 ref="topHeader" v-if="leftHaderShow && (singlePointLoginType || !leftMenuShow)" />
			<!-- 头部 - 正常登录进入 -->
			<topHeader ref="topHeader" v-else-if="leftHaderShow && !singlePointLoginType" />

			<div class="main clear-float" v-if="leftHaderShow">
				<!-- 菜单 -->
				<leftMenu v-if="leftMenuShow" ref="leftMenu" :leftMenu="leftMenu" />
				<!-- 页面内容 -->
				<div class="rightContent" v-if="showDom" :style="{ width: !leftMenuShow ? 'calc(100% - 40px)' : 'calc(100% - 296px)' }">
					<loading v-if="loading" :loadingText="loadingText" />
					<router-view />
					<div class="znkf" @click="gokefu">
						<p class="kfimg"><img src="../assets/img/kefu.png" alt="" srcset="" /></p>
						<p>智能客服</p>
					</div>
				</div>
			</div>
			<div v-if="!leftHaderShow">
				<!-- 菜单 -->
				<!-- 页面内容 -->
				<router-view />
			</div>
		</div>
		<el-dialog title="更正公告" :visible.sync="HTMLDialog" width="1000px" center top="5vh">
			<el-card class="HTML" v-if="HTML">
				<span v-html="HTML"></span>
			</el-card>
			<span slot="footer">
				<el-button size="small" @click="HTMLDialog = false">关 闭</el-button>
			</span>
		</el-dialog>
	</div>
</template>
<script>
function findRoute(item, path) {
	if (item.url && item.url === path) {
		return item
	}
	if (item.children) {
		for (let childItem of item.children) {
			let findItem = findRoute(childItem, path)
			if (findItem) {
				findItem.open = true
				return item
			}
		}
	}
}
import Vue from 'vue'
import { GetQueryString, treeMenu } from '@/plugin/util'
import { sysAuthMenus } from '@/axios/api/base.js'
import { areaTree } from '@/axios/api/Yl/system/menu'
import { projectVerify, publicProject } from '@/axios/api/Yl/bidTender_hj'
import { generatePreviewContent, read, ck } from '@/axios/api/forCCQ/specialCaseTreatment/correctionAnnounceMake.js'
import { sendWebsocket } from '@/websocket/websocket.js'
import { getWebsocketBaseUrl } from '@/plugin/envDataConfig.js'
import topHeader from '@/components/common/topHeader/topHeader.vue'
import topHeader2 from '@/components/common/topHeader/topHeader2.vue'
import leftMenu from '@/components/common/leftMenu/leftMenu.vue'
import { getLoginUrl, loginReturn } from '@/axios/api/Yl/hjGatheringSingleSignOn'
export default {
	components: { topHeader, topHeader2, leftMenu },
	data() {
		return {
			userInfo: JSON.parse(localStorage.getItem('userInfo')),
			loading: false,
			leftMenuShow: true, //从汇聚客户端进入项目的单页面
			leftHaderShow: true, //从汇聚客户端进入项目的单页面控制头部
			leftMenu: [],
			loadingText: '',
			showDom: true,
			socket0: null,
			typeEM: {
				1: '实质性条款变动待回复',
				2: '未通过实质性响应',
				3: '已通过实质性响应',
				4: '询标待应答,请及时查看',
				5: '已开启最终报价，请及时报价',
				6: '更正公告待查看',
				7: '框架协议待签订'
			},
			buttonEM: {
				1: '应答',
				2: '查看',
				3: '查看',
				4: '询标应答',
				5: '最终报价',
				6: '查看',
				7: '签订',
				8: '查看',
				14: '查看',
				9: '查看',
				10: '查看',
				11: '查看',
				15: '查看',
				12: '查看',
				13: '查看',
				16: '查看',
				17: '查看',
				18: '查看'
			},
			notifyList: [],
			notifyPromise: Promise.resolve(),
			HTMLDialog: false,
			HTML: '',
			singlePointLoginType: null,
		}
	},
	computed: {
		keyCollapse(){
			console.log("keyCollapse",this.$store.state.keyCollapse)
			return this.$store.state.keyCollapse
		}
	},
	watch: {
		'$route.path'() {
			this.showDom = false
			this.$nextTick(() => {
				this.showDom = true
			})
		},
	},
	methods: {
		gokefu() {
			window.open('https://zcycs.qiyukf.com/client?k=b841c0617769c4a871fbc65777cad8fd&wp=1&robotShuntSwitch=0&shuntId=0&language=zh-cn')
		},
		sysAuthMenus() {
			sysAuthMenus()
				.then((res) => {
					if (res && JSON.stringify(res.success) == 'false') return
					let leftMenu = []
					res.forEach((item) => {
						if (item.level > 0) leftMenu.push(item)
					})
					let list = lodash.cloneDeep(treeMenu(leftMenu))
					for (let item of list) {
						let find = findRoute(item, this.$route.path)
						if (find) {
							item.open = true
							break
						}
					}
					this.leftMenu = list
					this.$router.$menuRouter.formatRoutes(list, true)
					this.$store.commit('$setState', { key: 'menuList', value: JSON.stringify(this.leftMenu) })
					localStorage.setItem('menuList', JSON.stringify(this.leftMenu))
				})
				.catch((err) => {
					console.log(err)
				})
		},
		setAreaTree() {
			//区划树区级-查询
			areaTree({}).then((res) => {
				if (res && JSON.stringify(res.success) == 'false') {
					return
				}
				this.$store.commit('$setState', { key: 'areaTree', value: res })
				localStorage.setItem('areaTree', JSON.stringify(res))
			})
		},

		openSocket0() {
			// websocket相关代码
			let userInfo = JSON.parse(localStorage.getItem('userInfo'))
			if (userInfo.currbusiRoleCode != 'gys') {
				return
			}
			let type = getWebsocketBaseUrl()
			// ws://localhost:8080/websocket/30301
			let url = `wss://${type}/websocket/${userInfo.peopleId}?Authorization=${localStorage.getItem('token')}`
			let obj = {}
			this.socket0 = sendWebsocket(url, obj, this.wsMessage0, this.wsError0)
		},
		wsMessage0(res) {
			this.notify(JSON.parse(res.data), 5000)
		},
		notify(item, time) {
			this.notifyPromise = this.notifyPromise.then(this.$nextTick).then(() => {
				const notify = this.$notify({
					title: this.typeEM[item.type],
					message: this.getRender(item),
					duration: 0,
					showClose: [2, 3, 6, 7].includes(item.type)
				})
				this.notifyList[item.uuid] = notify
			})
		},

		getRender(item) {
			const h = this.$createElement
			return h('div', [
				h('div', { style: 'color: teal', class: 'dian3' }, `${item.msg}`),
				h('div', [
					h(
						'el-button',
						{
							props: {
								type: 'text',
								size: 'small'
							},
							on: {
								click: (e) => {
									this.clickBtn(item)
								}
							}
						},
						`${this.buttonEM[item.type]}`
					),
					h(
						'el-button',
						{
							props: {
								type: 'text',
								size: 'small'
							},
							on: {
								click: (e) => {
									this.clickBtninio(item)
								}
							}
						},
						`关闭`
					)
				])
			])
		},
		clickBtn(item) {
			if (item.type == 1) {
				this.$router.push('/materialTermsChange')
				this.read(item)
			}
			if (item.type == 2) {
				this.ck(item)
			}
			if (item.type == 3) {
				this.ck(item)
			}
			if (item.type == 4) {
				this.$router.push('/bidRecord')
				this.read(item)
			}
			if (item.type == 5) {
				this.$router.push('/participateBid/finalOffer')
				this.read(item)
			}
			if (item.type == 6) {
				// 更正公告待查看
				this.generatePreviewContent(item)
				this.read(item)
			}
			if (item.type == 7) {
				this.$router.push('/gysFrameAgreementSign')
				this.read(item)
			}

			//hh2-7新增
			if (item.type == 8) {
				this.$router.push('/bidOpeningHall/bidOpening')
				this.read(item)
			}
			if (item.type == 11) {
				this.$router.push('/bidOpeningHall/bidOpening')
				this.read(item)
			}
			if (item.type == 9) {
				this.$router.push('/bidOpeningHall/bidOpening')
				this.read(item)
			}
			if (item.type == 10) {
				this.$router.push('/bidOpeningHall/bidOpening')
				this.read(item)
			}
			if (item.type == 14) {
				this.$router.push('/bidOpeningHall/bidOpening')
				this.read(item)
			}
			if (item.type == 15) {
				this.$router.push('/bidOpeningHall/bidOpening')
				this.read(item)
			}
			if (item.type == 12) {
				this.$router.push('/bidOpeningHall/bidOpening')
				this.read(item)
			}
			if (item.type == 13) {
				this.$router.push('/bidOpeningHall/bidOpening')
				this.read(item)
			}
			if (item.type == 16) {
				this.$router.push('/bidOpeningHall/bidOpening')
				this.read(item)
			}
			if (item.type == 17) {
				this.$router.push('/bidOpeningHall/bidOpening')
				this.read(item)
			}
			if (item.type == 18) {
				this.$router.push('/bidOpeningHall/bidOpening')
				this.read(item)
			}
		},
		//点击关闭弹窗
		clickBtninio(e) {
			this.read(e)
			// this.closeNotify(e);
		},
		ck(item) {
			ck({ uuid: item.busiId })
				.then((res) => {
					if (res && JSON.stringify(res.success) == 'false') {
						return
					}
					if (item.type == 2) {
						this.$alert(
							`<p>未通过事项：${res.item}</p>
                            <p>代理机构联系人电话：${res.phone}</p>
                            <p>未通过原因：${res.reason}</p>`,
							'未通过实质性响应',
							{
								dangerouslyUseHTMLString: true
							}
						)
					} else {
						this.$alert(
							`<p>已通过事项：${res.item}</p>
                            <p>代理机构联系人电话：${res.phone}</p>`,
							'已通过实质性响应',
							{
								dangerouslyUseHTMLString: true
							}
						)
					}
					this.read(item)
				})
				.catch((err) => {
					console.log(err)
				})
		},
		read(item) {
			read(item)
				.then((res) => {
					if (res && JSON.stringify(res.success) == 'false') {
						return
					}
					this.closeNotify(item)
				})
				.catch((err) => {
					console.log(err)
				})
		},
		closeNotify(item) {
			this.notifyList[item.uuid].close()
		},
		generatePreviewContent(item) {
			generatePreviewContent({ uuid: item.busiId })
				.then((res) => {
					if (res && JSON.stringify(res.success) == 'false') {
						return
					}
					this.HTML = res
					this.$nextTick((e) => {
						this.HTMLDialog = true
					})
				})
				.catch((err) => {
					console.log(err)
				})
		},
		wsError0() {
			console.log('wsError0')
			// this.openSocket0();
		},

		closeSocket0() {
			if (this.socket0) {
				this.socket0.close()
				this.socket0.onclose()
			}
		},
		async projectVerify() {
			//供应商单点登录验证进入投标/开评标模块(获取用户信息跟token)
			let params = {
				subjectToken: GetQueryString('subjectToken'),
				projectId: GetQueryString('projectId'),
				packageId: GetQueryString('packageId')
			}
			await projectVerify(params)
				.then((res) => {
					if (res && JSON.stringify(res.success) == 'false') return
					localStorage.setItem('token', res.token)
					localStorage.setItem('userInfo', JSON.stringify(res))
					if (this.singlePointLoginType) {
						this.$nextTick(() => {
							this.showDom = true
						})
						this.sysAuthMenus()
					}
					if (this.leftHaderShow) {
						this.$refs.topHeader.init()
					}
				})
				.catch((err) => {
					console.log(err)
				})
		},
		async publicProject() {
			//供应商单点登录验证进入投标/开评标模块(获取用户信息跟token)
			let params = {
				subjectToken: GetQueryString('subjectToken'),
				projectId: GetQueryString('projectId'),
				packageId: GetQueryString('packageId')
			}
			await publicProject(params)
				.then((res) => {
					if (res && JSON.stringify(res.success) == 'false') return
					localStorage.setItem('token', res.token)
					localStorage.setItem('userInfo', JSON.stringify(res))
					if (this.singlePointLoginType) {
						this.$nextTick(() => {
							this.showDom = true
						})
						this.sysAuthMenus()
					}
					if (this.leftHaderShow) {
						this.$refs.topHeader.init()
					}
				})
				.catch((err) => {
					console.log(err)
				})
		},
		async loginReturn() {
			let token = this.getUrlParameters()
			// console.log(token)
			await loginReturn({ subjectAccessToken: token.subjectAccessToken })
				.then((response) => {
					console.log(response)
					if (response && JSON.stringify(response.success) == 'false') return
					localStorage.setItem('token', response.token)
					localStorage.setItem('userInfo', JSON.stringify(response))
					this.$nextTick(() => {
						this.showDom = true
						this.$refs.topHeader.init()
					})
					this.sysAuthMenus()
					setTimeout(() => {
						this.$router.push('/workbench/agency')
					}, 100)
				})
				.catch()
		},
		getUrlParameters(yrl) {
			var params = {},
				url = window.location.href,
				query = url.split('?')[1]

			if (query) {
				var queryArr = query.split('&')
				for (var i = 0; i < queryArr.length; i++) {
					var pair = queryArr[i].split('=')
					params[pair[0]] = decodeURIComponent(pair[1])
				}
			}

			return params
		},
	},
	beforeCreate() {
		Vue.prototype.$loading = {
			show: () => {
				this.loading = true
			},
			hide: () => {
				this.loading = false
				this.loadingText = ''
			},
			text: (loadingText) => {
				this.loadingText = loadingText
			}
		}
	},
	created() {
		this.singlePointLoginType = GetQueryString('type') //从汇集客户端进入供应商首页
		this.leftMenuShow = this.$route.meta && this.$route.meta.leftMenuShow == false ? this.$route.meta.leftMenuShow : true
		this.leftHaderShow = this.$route.meta && this.$route.meta.leftHaderShow == false ? this.$route.meta.leftHaderShow : true
		// console.log(this.singlePointLoginType)
		// debugger
		if (this.singlePointLoginType) {
			this.projectVerify()
			this.showDom = false
		} else if (this.leftMenuShow && this.leftHaderShow) {
			if (this.$route.path == '/hjGatheringSingleSignOn') {
				this.loginReturn()
			} else {
				this.openSocket0()
				this.sysAuthMenus()
				let userInfo = JSON.parse(localStorage.getItem('userInfo'))
				if (userInfo.operatorType == '1' || userInfo.operatorType == '2') this.setAreaTree()
			}
		} else {
			console.log(3)
			console.log(this.$route.path)
			if (this.$route.path == '/demandPublicitySingleSignOn_hj') {
				this.publicProject()
			} else {
				this.projectVerify()
			}
		}
	},
	mounted() {}
}
</script>
<style scoped>
.main {
	width: 100%;
	min-height: calc(100vh - 58px);
	background: #f0f2f5;
}

.rightContent {
	width: calc(100% - 296px);
	height: calc(100vh - 58px - 40px);
	margin: 20px;
	background: #ffffff;
	float: right;
	border: 1px solid #dcdfe6;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12), 0 0 6px 0 rgba(0, 0, 0, 0.04);
	position: relative;
}
.rightContent .znkf {
	position: absolute;
	right: -20px;
	bottom: 80px;
	width: 70px;
	height: 70px;
	background-color: #717e92;
	color: #ffffff;
	z-index: 999;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}
.rightContent .znkf:hover {
	background-color: #2d8cf0;
}
.rightContent .znkf .kfimg {
	margin-bottom: 5px;
}

.rightContent >>> .ivu-select-disabled .ivu-select-selection {
	color: #515a6e;
}

.dian3 {
	text-overflow: -o-ellipsis-lastline;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
}
</style>
<style lang="less" scoped>
//@import url(); 引入公共css类
.HTML {
	max-height: 70vh;
	overflow-y: auto;

	/deep/img {
		max-width: 100%;
	}
}
</style>
